<template>
  <BlockUI :blocked="loading>0">
    <div class="p-grid" style="min-height: 120px">
      <div class="p-col-12" v-if="erro != ''">
        <h5 style="color: red">{{ erro }}</h5>
      </div>
      <div class="p-col-12 logs" v-else>
        <div class="log-item p-p-3 p-mb-3" v-for="l of logs" :key="l.id">
          <p class="p-mb-0"><strong>Data:</strong> {{ l.data }}</p>
          <p class="p-mb-0"><strong>URL:</strong> {{ l.url }}</p>
          <p class="p-mb-0">
            <strong>Dados da requisição:</strong>
            <pre style="text-wrap: wrap;">{{ l.request }}</pre>
          </p>
          <p class="p-mb-0">
            <strong>Dados da resposta:</strong>
            <pre style="text-wrap: wrap;">{{ l.response }}</pre>
          </p>
        </div>
      </div>
    </div>
    <div class="center-loading" v-if="loading>0">
      <ProgressSpinner />
    </div>
  </BlockUI>
</template>

<script>
export default {
  props: {
    id: Number,
  },
  name: "LogIntegracaoView",
  data() {
    return {
      // 1. Controle de apresentação
      loading: 0,

      // 2. Informações
      logs: [],
      erro: "",

    }
  },
  mounted() {
    this.carregarDados();
  },
  methods: {
    carregarDados: async function() {
      this.loading++;
      
      try {
      
        const response = await this.$api.get("/processos/integracoes/logs/" + this.id);

        const dados = response.data;

        if (dados.success) { 
          this.logs = dados.data;
        } else {
          console.log(dados);
          this.erro = dados.message;
        }

      } catch (error) {
        console.log(error);
        this.erro = "Erro ao acessar as informações!";
      }

      this.loading--;

    }
  }
}
</script>

<style lang="scss" scoped>

  .log-item {
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  .logs .log-item:nth-child(even) {
    background: rgb(247, 247, 247);
  }

</style>