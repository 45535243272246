<template>

  <div class="is-component center-loading" v-if="loading>0">
    <ProgressSpinner />
  </div>

  <div class="center-loading" v-else-if="!isComponent">

    <div class="p-text-center" v-if="seguradoras.length == 0">

      <h3>Nenhuma seguradora habilitada para análise automática!</h3>
      <h4>Aguarde a análise manual pela corretora.</h4>
      <Button
        icon="pi pi-eye"
        label="Acompanhar"
        class="p-button-secondary p-mt-3"
        @click="$router.push('/processos')"
      ></Button>

    </div>

    <div class="p-text-center" v-else>

      <h3 class="p-mb-5">Analisando, aguarde...</h3>

      <div v-for="s of seguradoras" class="logo-seguradora" :key="s.id">
        <img 
          :src="'/assets/layout/images/' + s.logo" 
          :alt="s.nome" 
          :class="'logo-seguradora-' + s.status"
        />
        <i class="pi pi-exclamation-triangle" v-if="s.status == 'warning'"></i>
        <i class="pi pi-times" v-if="s.status == 'error'"></i>
        <i class="pi pi-check" v-if="s.status == 'success'"></i>
        <i class="pi pi-spin pi-spinner" v-if="s.status == 'executing'"></i>
      </div>

    </div>

  </div>

  <div class="is-component" v-else>

    <div class="p-text-center" v-if="seguradoras.length == 0">

      <h4>Aguarde a análise pela corretora.</h4>

    </div>

    <div class="p-text-center" v-else>

      <h3 class="p-mb-5" v-if="isAutomatic">Analise automática em execução...<small class="p-d-block">Não saia da página até finalizar.</small></h3>
      <h3 class="p-mb-5" v-else-if="isManual">Analise pela corretora está em execução...<small class="p-d-block">Você pode sair da página se desejar.</small></h3>

      <div v-for="s of seguradoras" class="logo-seguradora" :key="s.id">
        <img 
          :src="'/assets/layout/images/' + s.logo" 
          :alt="s.nome" 
          :class="'logo-seguradora-' + s.status"
        />
        <i class="pi pi-exclamation-triangle" v-if="s.status == 'warning'"></i>
        <i class="pi pi-times" v-if="s.status == 'error'"></i>
        <i class="pi pi-check" v-if="s.status == 'success'"></i>
        <i class="pi pi-spin pi-spinner" v-if="s.status == 'executing'"></i>
      </div>

    </div>

  </div>

  <Toast position="top-right" />
  
</template>

<script>

export default {

  props: {
    analisando: Boolean,
    processo: String,
  },

  emits: [
    "end",
    "endAutomatic",
    "endManual",
    "update:analisando"
  ],

  data() {
    return {

      loading: 1,

      isComponent: false,
      isAutomatic: false,
      isManual: false,

      seguradoras: [],

      processo_id: 0,
      
    };
  },

  mounted() {

    if(typeof this.processo != "undefined") {
      this.processo_id = this.processo;
      this.isComponent = true;
    } else if(typeof this.$route.params.id == "string" && this.$route.params.id != "") {
      this.processo_id = this.$route.params.id;
    } else {
      this.$router.push('/processos');
    }

    this.init();

  },

  watch: {

    analisando: {
      handler: async function (analisando) {
        if(analisando) {
          this.process();
        }
      },
      deep: true,
      immediate: true,
    },

  },

  methods: {

    async init() {

      this.loading = 1;

      let response = await this.$api.get("/processos/integracoes/seguradoras/" + this.processo_id);
      response = response.data;

      if(response.success) {

        this.seguradoras = response.data;

      } else {

        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: response.message,
          life: 5000,
        });

      }

      this.loading--;

    },

    async process() {
      
      const self = this;

      for(let s of this.seguradoras) {

        if(s.status != "pending")
          continue;

        if(!s.integrada && s.status == "pending") {
          s.status = "executing";
          this.isManual = true;
          continue;
        }

        this.isAutomatic = true;

        s.status = "executing";

        this.$api.get("/processos/integracoes/" + s.id + "/analisar-fianca/" + this.processo_id).then(function(response) {

          response = response.data;

          if(response.success) {
            
            if(response.data.result == "APROVADA") {
              
              s.status = "success";

              self.$toast.add({
                severity: "success",
                summary: "Sucesso!",
                detail: "Cadastro aprovado com sucesso na " + s.nome + "!",
                life: 4000,
              });

            } else if (response.data.result == "RECUSADA") {
              
              s.status = "error";

            } else {
 
              s.status = 'warning';
              
              self.$toast.add({
                severity: "warn",
                summary: "Em Análise!",
                detail: "Aguardando análise da " + s.nome + "...",
                life: 4000,
              });

            }

          } else {

            s.integrada = false;

            console.log(s.nome);
            console.log(response);

          }

        });

      }

      let monitor = setInterval(async() => {

        let fim = true;
        let aprovado = false;

        let isManual = false;
        let isAutomatic = false;
                  
        for(let s of self.seguradoras) {

          if(s.status == "pending" || s.status == "executing") {
            fim = false;

            if(!s.integrada) {
              isManual = true;
            } else {
              isAutomatic = true;
            }

          } else if(s.status == "success") {
            aprovado = true;
          }

        }

        this.isManual = isManual;
        this.isAutomatic = isAutomatic;

        if(!isManual) {
          this.$emit("endManual");
        }

        if(!isAutomatic) {
          this.$emit("endAutomatic");
        }

        if(fim) {

          clearInterval(monitor);

          self.$emit("end");
          self.$emit("update:analisando", false);
          
          if(!aprovado) {

            self.$toast.add({
              severity: "warn",
              summary: "Em Análise!",
              detail: "Cadastro não aprovado automaticamente! Em análise pela corretora...",
              life: 4000,
            });

          } else {

            self.$toast.add({
              severity: "success",
              summary: "Sucesso!",
              detail: "Cadastro aprovado!",
              life: 4000,
            });

          }

        }

      }, 1000);

    }

  },

};
</script>

<style scoped>

  .center-loading {
    top: 50vh;
    width: 100%;
  }

  .logo-seguradora img {
    max-width: 100%;
    height: auto;
  }

  .logo-seguradora {
    width: 200px;
    margin: 0 1rem;
    padding: 0 0.5rem;
    background-color: transparent;
    position: relative;
    display: inline-block;
  }

  .logo-seguradora .pi {
    position: absolute;
    font-size: 1.5rem;
    top: -0.5rem;
    right: -0.5rem;
    background: white;
    padding: 0.4rem;
    border-radius: 50%;
    font-weight: bold;
  }

  .logo-seguradora-pending {
    filter: grayscale(100%);
    opacity: 0.5;
  }

  .logo-seguradora-executing {
    filter: grayscale(100%);
  }

  .logo-seguradora .pi-check {
    color: green;
  }

  .logo-seguradora .pi-times {
    color: red;
  }

  .logo-seguradora .pi-exclamation-triangle {
    color: rgb(241, 229, 5);
  }

  .logo-seguradora .pi-spin {
    color: #ff6800;
  }

</style>