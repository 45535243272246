<template>
  <div>
    <div class="p-grid">

      <div class="p-col-12 p-mt-2">
        <label for="detalhes" class="p-d-block p-mb-2">Informações adicionais:</label>
        <Textarea 
          id="detalhes"
          v-model="detalhes" 
          :autoResize="true" 
          rows="1" 
          cols="30" 
          style="width: 100%;" 
          :class="(fieldsError.some(v => v === 'detalhes'))?'p-invalid':''"
        />
      </div>

      <div class="p-col-12 p-mt-2">
        <FileUpload 
          chooseLabel="Anexar Arquivos" 
          mode="basic" 
          name="upload[]" 
          :url="$api.url() + '/arquivos'"
          :auto="true" 
          :multiple="true"
          :maxFileSize="50000000"
          accept="image/*,video/*,application/pdf,application/zip"
          @before-send="upload_headers"
          @upload="upload_finalizado"
          @progress="upload_progress"
          @error="upload_error"
        >
        </FileUpload>
      </div>
      <div class="p-col-12" v-show="progress_upload > 0 && progress_upload < 100">
        <ProgressBar :value="progress_upload"></ProgressBar>
      </div>
      <div class="p-col-12 p-mt-2" v-show="arquivos.length > 0">
        <span v-for="a of arquivos" :key=a.id>
          <Button :label="a.filename_original" class="p-mr-2 p-button-secondary p-button-sm" style="width: auto" icon="pi pi-file" @click="upload_click($event, a)"></Button>
        </span>
      </div>

      <div class="p-col-12" v-show="fieldsError.some(v => v === 'arquivos')">
        <span style="color: red">É necessário anexar pelo menos um arquivo.</span>
      </div>

      <div class="p-col-12" v-show="acao != ''">
        <Button label="Enviar" icon="pi pi-check" class="p-button p-button-success p-mt-4" @click="registrar($event)" />
      </div>

    </div>

    <ConfirmPopup></ConfirmPopup>
    <Toast position="top-right" />
  </div>
</template>
<script>
export default {
  props: {
    processo: Object,
  },
  emits: [
    "save"
  ],
  data() {
    return {

      fieldsError: [],

      detalhes: "",

      progress_upload: 0,
      arquivos : [],

    }
  },
  methods: {

    registrar(event) {

      this.fieldsError = [];

      let arquivos = [];
      if(this.arquivos.length > 0) {
        for(const a of this.arquivos) {
          arquivos.push(a.id);
        }
      }

      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: 'Confirma o envio?',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-success',
        accept: () => {

          self.$api
            .post(
              "/processos/enviar-contrato",
              {
                "processo" : self.processo.id,
                "detalhes" : self.detalhes,
                "arquivos": arquivos,
              }
            )
            .then(function (response) {

              self.$toast.add({
                severity: (response.data.success)?"success":"error",
                summary: (response.data.success)?"Sucesso!":"ERRO!",
                detail: response.data.message,
                life: (response.data.success)?3000:5000,
              });

              self.$emit("save");

            })
            .catch(function (erro) {

              if (typeof erro.response != "undefined") {

                const response = erro.response;

                if(typeof response.data.fields != "undefined") {

                  const dicionario = {            
                  };

                  for(const f of response.data.fields) {

                    if(typeof dicionario[f] != "undefined") {
                      self.fieldsError.push(dicionario[f]);
                    } else {
                      self.fieldsError.push(f);
                    }

                  }

                }

                self.$toast.add({
                  severity: "error",
                  summary: "ERRO!",
                  detail: response.data.message,
                  life: 5000,
                });

              }

            });

        }
      });

    },

    upload_headers(event) {
        event.xhr.setRequestHeader("Authorization", "Bearer " + this.$auth.token());
    },

    upload_progress(event) {
        this.progress_upload = event.progress;
    },

    upload_error(event) {
        const res = event.xhr.response;

        self.$toast.add({
            severity: "error",
            summary: "ERRO!",
            detail: res.message,
            life: 5000,
        });

    },

    upload_click(event, file) {

        const self = this;

        this.$confirm.require({
            target: event.currentTarget,
            message: 'O que deseja fazer?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Abrir",
            rejectLabel: "Excluir",
            acceptClass: "p-button-success",
            rejectClass: "p-button-danger",
            accept: () => {
                window.open(self.$api.url() + '/arquivos/' + file.filename_final, '_blank');
            },
            reject: () => {

                self.$api
                    .post(
                    "/arquivos/" + file.id, {"_method" : "DELETE"})
                    .then(function (response) {

                        const idx = self.arquivos.findIndex(e => e.id == file.id);
                        self.arquivos.splice(idx, 1);

                        self.$toast.add({
                            severity: (response.data.success)?"success":"error",
                            summary: (response.data.success)?"Sucesso!":"ERRO!",
                            detail: response.data.message,
                            life: (response.data.success)?3000:5000,
                        });

                    })
                    .catch(function (erro) {

                        if (typeof erro.response != "undefined") {

                            const response = erro.response;

                            self.$toast.add({
                                severity: "error",
                                summary: "ERRO!",
                                detail: response.data.message,
                                life: 5000,
                            });
                        }

                    });

            }
        });

    },

    upload_finalizado(event) {
        const res = JSON.parse(event.xhr.response);
        this.arquivos = this.arquivos.concat(res.data);
    },

  }
}
</script>
<style scoped>

  :deep(.p-button-success.button-contrato) {
    background-color: #f47921 !important;
    border-color: #f47921 !important;
  }

</style>