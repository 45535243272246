<template>
  <div>
    <div class="p-grid">

      <div class="p-col-12">
        <Button 
          label="registrar análise" 
          icon="pi pi-exclamation-triangle" 
          class="p-button p-button-warning p-mr-3 p-mb-3" 
          @click="acao = 'ANALISE'" 
          v-show="acao == '' || acao == 'ANALISE'"
        />
        <Button 
          label="solicitar contrato" 
          icon="pi pi-file" 
          class="p-button p-button-success button-contrato p-mr-3 p-mb-3" 
          @click="acao = 'AGUARDANDO_CONTRATO'" 
          v-show="acao == '' || acao == 'AGUARDANDO_CONTRATO'"
        />
        <Button 
          label="apólice emitida" 
          icon="pi pi-check" 
          class="p-button p-button-success p-mr-3 p-mb-3" 
          @click="acao = 'SEGURO_REGISTRADO'" 
          v-show="acao == '' || acao == 'SEGURO_REGISTRADO'"
        />
        <Button 
          label="registro negado" 
          icon="pi pi-times" 
          class="p-button p-button-danger p-mr-3 p-mb-3" 
          @click="acao = 'REGISTRO_NEGADO'" 
          v-show="acao == '' || acao == 'REGISTRO_NEGADO'"
        />
        <Button 
          label="apólice cancelada" 
          icon="pi pi-times" 
          class="p-button p-button-danger p-mb-3" 
          @click="acao = 'APOLICE_CANCELADA'" 
          v-show="acao == '' || acao == 'APOLICE_CANCELADA'"
        />

      </div>

      <div class="p-col-12" v-show="acao == 'ANALISE'">

        <div class="analise p-mb-5" v-for="s of analises" :key="s.id">

          <div class="seguradora">
            <span class="seguradora-nome"><strong><i class="pi pi-globe"></i> {{ s.nome }}</strong></span>
            <SelectButton v-model="s.status" :options="s.status_opcoes" optionLabel="nome" optionValue="id" />
          </div>

          <div v-show="s.status == 'APROVADO'">

            <div class="plano p-mx-3 p-mt-3" v-for="p of s.planos" :key="p.id">
              <div class="p-d-inline-block">
                <label :for="'valor_' + p.id" class="p-d-block p-mb-2">Plano {{ p.nome }}:</label>
                <InputNumber
                  :id="'valor_' + p.id"
                  mode="currency"
                  currency="BRL"
                  locale="pt-BR"
                  v-model="p.valor"
                  :class="
                    fieldsError.some((v) => v === 'valor_' + p.id)
                      ? 'p-invalid'
                      : ''
                  "
                  @focus="$utils.inputNumberFocus"
                />
              </div>

              <div class="p-d-inline-block p-ml-2">
                <label :for="'parcelas_' + p.id" class="p-d-block p-mb-2">Parcelas:</label>
                <InputNumber
                  :id="'parcelas_' + p.id"
                  v-model="p.parcelas"
                  :class="
                    fieldsError.some((v) => v === 'parcelas_' + p.id)
                      ? 'p-invalid'
                      : ''
                  "
                  @focus="$utils.inputNumberFocus"
                />
              </div>

            </div>

            <div class="p-mx-3 p-mt-3">
              <FileUpload 
                chooseLabel="Anexar Arquivos" 
                mode="basic" 
                name="upload[]" 
                :url="$api.url() + '/arquivos'"
                :auto="true" 
                :multiple="true"
                :maxFileSize="50000000"
                accept="image/*,video/*,application/pdf,application/zip"
                @before-send="upload_headers"
                @upload="upload_finalizado($event, s)"
                @progress="upload_progress($event, s)"
                @error="upload_error"
              >
              </FileUpload>
            </div>
            
            <div class="p-mx-3 p-mt-2" v-show="progress_upload_analises[s.id] > 0 && progress_upload_analises[s.id] < 100">
              <ProgressBar :value="progress_upload_analises[s.id]"></ProgressBar>
            </div>

            <div class="p-mx-3 p-mt-3" v-show="s.arquivos.length > 0">
              <span v-for="a of s.arquivos" :key=a.id>
                <Button :label="a.filename_original" class="p-mr-2 p-button-secondary p-button-sm" style="width: auto" icon="pi pi-file" @click="upload_click($event, a, s)"></Button>
              </span>
            </div>

          </div>

          <div v-show="s.status == 'RECUSADO'">
          
            <div class="p-mx-3 p-mt-3">
              <FileUpload 
                chooseLabel="Anexar Arquivos" 
                mode="basic" 
                name="upload[]" 
                :url="$api.url() + '/arquivos'"
                :auto="true" 
                :multiple="true"
                :maxFileSize="50000000"
                accept="image/*,video/*,application/pdf,application/zip"
                @before-send="upload_headers"
                @upload="upload_finalizado($event, s)"
                @progress="upload_progress($event, s)"
                @error="upload_error"
              >
              </FileUpload>
            </div>
            
            <div class="p-mx-3 p-mt-2" v-show="progress_upload_analises[s.id] > 0 && progress_upload_analises[s.id] < 100">
              <ProgressBar :value="progress_upload_analises[s.id]"></ProgressBar>
            </div>

            <div class="p-mx-3 p-mt-3" v-show="s.arquivos.length > 0">
              <span v-for="a of s.arquivos" :key=a.id>
                <Button :label="a.filename_original" class="p-mr-2 p-button-secondary p-button-sm" style="width: auto" icon="pi pi-file" @click="upload_click($event, a, s)"></Button>
              </span>
            </div>

          </div>

        </div>

      </div>

      <div class="p-col-12 p-mt-2" v-show="acao != ''">
        <label for="detalhes" class="p-d-block p-mb-2">Detalhes:</label>
        <Textarea id="detalhes" v-model="detalhes" :autoResize="true" rows="1" cols="30" style="width: 100%;" />
      </div>

      <div class="p-col-12" v-show="acao == 'SEGURO_REGISTRADO'">
        <label for="apolice" class="p-d-block p-mb-2">Apólice:</label>
        <InputText id="apolice" type="text" v-model="apolice" style="width: 50%;" />
      </div>

      <div class="p-col-12 p-mt-2" v-show="acao != ''">
        <FileUpload 
          chooseLabel="Anexar Arquivos" 
          mode="basic" 
          name="upload[]" 
          :url="$api.url() + '/arquivos'"
          :auto="true" 
          :multiple="true"
          :maxFileSize="50000000"
          accept="image/*,video/*,application/pdf,application/zip"
          @before-send="upload_headers"
          @upload="upload_finalizado"
          @progress="upload_progress"
          @error="upload_error"
        >
        </FileUpload>
      </div>

      <div class="p-col-12" v-show="progress_upload > 0 && progress_upload < 100">
        <ProgressBar :value="progress_upload"></ProgressBar>
      </div>

      <div class="p-col-12 p-mt-2" v-show="arquivos.length > 0">
        <span v-for="a of arquivos" :key=a.id>
          <Button :label="a.filename_original" class="p-mr-2 p-button-secondary p-button-sm" style="width: auto" icon="pi pi-file" @click="upload_click($event, a)"></Button>
        </span>
      </div>

      <div class="p-col-12" v-show="acao != ''">
        <Button label="Registrar" icon="pi pi-check" class="p-button p-button-success p-mt-4" @click="registrar($event)" />
      </div>

    </div>

    <ConfirmPopup></ConfirmPopup>
    <Toast position="top-right" />
  </div>
</template>
<script>
export default {
  props: {
    processo: Object,
  },
  emits: [
    "save"
  ],
  data() {
    return {

      acao: "", // ANALISE SEGURO_REGISTRADO REGISTRO_NEGADO

      fieldsError: [],

      detalhes: "",
      analises: [],
      apolice: "",

      progress_upload: 0,
      progress_upload_analises: {},
      arquivos: [],

      status_analises: [
        {id : "APROVADO", nome : "Aprovado"},
        {id : "RECUSADO", nome : "Recusado"},
        {id : "NAO_ANALISADO", nome : "Não Analisado"},
      ],

      status_analises_recusado: [
        {id : "RECUSADO", nome : "Recusado"},
        {id : "NAO_ANALISADO", nome : "Não Analisado"},
      ],

    }
  },
  mounted() {

    const self = this;

    //TODO VERIFICAR PORQUE NÃO TRÁS APENAS OS CAMPOS SELECIONADOS DO RELACIONAMENTO
    this.$api.get("/seguradoras?fields=nome,analiseNovo,analiseMudancaGarantia&relationships=planos&filters={%22status%22:[%22ATIVO%22]}").then(async function (response) {
      const dados = response.data;

      if (dados.success) {

        // #region Verificar Local Final //
        // TODO
        const im_cnf = (await self.$api.get("/imobiliarias/" + self.processo.imobiliaria + "/bloqueios")).data;
        // #endregion //

        for(const s of dados.data) {

          let negado = im_cnf.findIndex((bl) => bl.seguradora == s.id) != -1;
          if(negado == false) {
            if(self.processo.novo) {
              negado = s.analiseNovo == 0;
            } else {
              negado = s.analiseMudancaGarantia == 0;
            }
          }

          let a = {
            id: s.id,
            nome: s.nome,
            status: 'NAO_ANALISADO',
            status_opcoes: (negado)?self.status_analises_recusado:self.status_analises,
            planos: [],
            arquivos: [],
          };

          self.progress_upload_analises[s.id] = 0;

          for(const p of s.planos) {

            if(p.status != "ATIVO") {
              continue;
            }

            // CALCULO DO VALOR //

            let formula = p.formula;

            formula = formula.replaceAll('TEMPO_CONTRATO', self.processo.meses);
            formula = formula.replaceAll('ALUGUEL', self.processo.valor_aluguel);
            formula = formula.replaceAll('PINTURA_INTERNA', self.processo.valor_pintura_interna);
            formula = formula.replaceAll('PINTURA_EXTERNA', self.processo.valor_pintura_externa);
            formula = formula.replaceAll('DANOS_IMOVEL', self.processo.valor_danos_imovel);
            formula = formula.replaceAll('MULTAS_CONTRATUAIS', self.processo.valor_multas_contratuais);
            formula = formula.replaceAll('CONDOMINIO', self.processo.valor_condominio);
            formula = formula.replaceAll('IPTU', self.processo.valor_iptu);
            formula = formula.replaceAll('AGUA', self.processo.valor_agua);
            formula = formula.replaceAll('GAS', self.processo.valor_gas);
            formula = formula.replaceAll('LUZ', self.processo.valor_luz);
            formula = formula.replaceAll('ALUGUEL', self.processo.valor_aluguel);

            let taxa = 0;
            let valor = 0;

            if(typeof self.processo.imobiliaria_taxas != 'undefined' 
            && typeof self.processo.imobiliaria_taxas[s.id] != "undefined" 
            && typeof self.processo.imobiliaria_taxas[s.id][p.id] != "undefined")
              taxa = self.processo.imobiliaria_taxas[s.id][p.id];

            if(taxa == 0 && p.taxa > 0)
              taxa = p.taxa;

            if(taxa == 0 && s.taxa > 0)
              taxa = s.taxa;

            if(taxa != 0)
              valor = eval(formula.replaceAll('TAXA', taxa));

            /////////////////////////////

            a.planos.push({
              id: p.id,
              nome: p.nome,
              parcelas: (self.processo.meses -1),
              valor: valor
            });

          }

          self.analises.push(a);

        }

      }

    });

  },
  methods: {

    registrar(event) {

      this.fieldsError = [];

      let arquivos = [];
      if(this.arquivos.length > 0) {
        for(const a of this.arquivos) {
          arquivos.push(a.id);
        }
      }

      for(const an of this.analises) {
        let arquivos = [];
        if(an.arquivos.length > 0) {
          for(const a of an.arquivos) {
            arquivos.push(a.id);
          }
        }
        an.arquivos = arquivos;
      }

      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: 'Confirma o registro?',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-success',
        accept: () => {

          if(self.acao == "ANALISE") {

            for(const s of self.analises) {
              for(const p of s.planos) {
                if(s.status == "APROVADO" && p.valor < 0) {
                  self.fieldsError.push('valor_' + p.id);
                }
              }
            }

            if(self.fieldsError.length > 0) {

              self.$toast.add({
                severity: "error",
                summary: "ERRO!",
                detail: "Todos os valores precisam ser informados!",
                life: 5000,
              });

              return;
            }

          }

          self.$api
            .post(
              "/processos/acao-manual",
              {
                "processo" : self.processo.id,
                "acao" : self.acao,
                "detalhes" : self.detalhes,
                "apolice" : self.apolice,
                "analises" : self.analises,
                "arquivos": arquivos,
              }
            )
            .then(function (response) {

              self.$toast.add({
                severity: (response.data.success)?"success":"error",
                summary: (response.data.success)?"Sucesso!":"ERRO!",
                detail: response.data.message,
                life: (response.data.success)?3000:5000,
              });

              self.$emit("save");

            })
            .catch(function (erro) {

              if (typeof erro.response != "undefined") {

                const response = erro.response;

                if(typeof response.data.fields != "undefined") {

                  const dicionario = {            
                  };

                  for(const f of response.data.fields) {

                    if(typeof dicionario[f] != "undefined") {
                      self.fieldsError.push(dicionario[f]);
                    } else {
                      self.fieldsError.push(f);
                    }

                  }

                }

                self.$toast.add({
                  severity: "error",
                  summary: "ERRO!",
                  detail: response.data.message,
                  life: 5000,
                });

              }

            });

        }
      });

    },

    upload_headers(event) {
        event.xhr.setRequestHeader("Authorization", "Bearer " + this.$auth.token());
    },

    upload_progress(event, seguradora = null) {

      if(typeof seguradora == "object" && seguradora != null) {
        this.progress_upload_analises[seguradora.id] = event.progress;
      } else {
        this.progress_upload = event.progress;
      }

    },

    upload_error(event) {
        const res = event.xhr.response;

        self.$toast.add({
            severity: "error",
            summary: "ERRO!",
            detail: res.message,
            life: 5000,
        });

    },

    upload_click(event, file, seguradora = null) {

        const self = this;

        this.$confirm.require({
            target: event.currentTarget,
            message: 'O que deseja fazer?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Abrir",
            rejectLabel: "Excluir",
            acceptClass: "p-button-success",
            rejectClass: "p-button-danger",
            accept: () => {
                window.open(self.$api.url() + '/arquivos/' + file.filename_final, '_blank');
            },
            reject: () => {

                self.$api
                    .post(
                    "/arquivos/" + file.id, {"_method" : "DELETE"})
                    .then(function (response) {

                      if(typeof seguradora == "object" && seguradora != null) {

                        const idx = seguradora.arquivos.findIndex(e => e.id == file.id);
                        seguradora.arquivos.splice(idx, 1);

                      } else {

                        const idx = self.arquivos.findIndex(e => e.id == file.id);
                        self.arquivos.splice(idx, 1);

                      }

                      self.$toast.add({
                          severity: (response.data.success)?"success":"error",
                          summary: (response.data.success)?"Sucesso!":"ERRO!",
                          detail: response.data.message,
                          life: (response.data.success)?3000:5000,
                      });

                    })
                    .catch(function (erro) {

                        if (typeof erro.response != "undefined") {

                            const response = erro.response;

                            self.$toast.add({
                                severity: "error",
                                summary: "ERRO!",
                                detail: response.data.message,
                                life: 5000,
                            });
                        }

                    });

            }
        });

    },

    upload_finalizado(event, seguradora = null) {
      const res = JSON.parse(event.xhr.response);

      if(typeof seguradora == "object" && seguradora != null) {
        seguradora.arquivos = seguradora.arquivos.concat(res.data);
      } else {
        this.arquivos = this.arquivos.concat(res.data);
      }

    },

  }
}
</script>
<style scoped>

  :deep(.p-selectbutton .p-button) {
    padding-top: 0.25rem;
    padding-bottom: 0.3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  :deep(.p-selectbutton) {
    display: inline-flex;
    margin-left: 1rem;
  }

  :deep(.p-button-success.button-contrato) {
    background-color: #f47921;
    border-color: #f47921;
  }

  :deep(.p-button-success.button-contrato:hover) {
    background-color: #f48e21;
    border-color: #f48e21;
  }

</style>