<template>
  <div>
    <div class="p-grid">
      <div class="p-col-12 p-md-4">
        <h3><i class="pi pi-file" style="font-size: 1.6rem"></i> Análise de Seguro Fiança</h3>
      </div>
      <div class="p-col-12 p-md-8 p-text-right">
        <SplitButton label="reanalizar" icon="pi pi-bolt" class="p-button-info p-mr-2" :model="seguradoras_reanalizar" v-if="$auth.currentUser.imobiliaria == null && processo != null"></SplitButton>
        <!-- <Button label="reanalizar" icon="pi pi-bolt"  class="p-button p-button-info p-mr-2" @click="analisando = true" /> -->
        <Button label="clonar análise" icon="pi pi-clone" v-if="$auth.userCan('SEGURO_FIANCA_CADASTRAR') && processo != null" class="p-button p-button-info p-mr-2" @click="$router.push({ path: `/processo/clonar/${processo.id}` })" />
        <Button label="enviar contrato" icon="pi pi-file" class="p-button p-button-warning button-contrato p-mr-2" v-if="processo_status == 'AGUARDANDO_CONTRATO'" @click="enviarContrato" />
        <Button label="registrar encaminhamento" icon="pi pi-check" class="p-button p-button-warning p-mr-2" v-if="$auth.currentUser.imobiliaria == null" @click="registrarEncaminhamento" />
        <Button label="nova análise" icon="pi pi-plus" class="p-button p-button-secondary"  @click="$router.push({ path: `/processo` })" />
      </div>
    </div>

    <BlockUI :blocked="loading>0">

      <div class="p-grid">
        <div class="p-col-12">

          <Accordion :multiple="true" v-model:activeIndex="tabsAtivas" class="w-full">

            <!-- #region Locação -->
            <AccordionTab header="Locação">

              <div class="p-grid">
              
                <div class="p-col-12 p-md-6">
                  <strong>Imóvel</strong><br>
                  {{ imovel }}
                </div>
                <div class="p-col-12 p-md-3">
                  <strong>Finalidade de Locação</strong><br>
                  {{ finalidade }}
                </div>
                <div class="p-col-12 p-md-3">
                  <strong>Motivo de Locação</strong><br>
                  {{ motivo }}
                </div>

              </div>

              <div class="p-grid p-mt-1">
                
                <div class="p-col-12 p-md-6">
                  <strong>Ramo de Atividade</strong><br>
                  {{ ramo }}
                </div>
                <div class="p-col-12 p-md-3">
                  <strong>CNPJ da Empresa</strong><br>
                  {{ cnpj }}
                </div>
                <div class="p-col-12 p-md-3">
                  <strong>Imobiliária</strong><br>
                  {{ imobiliariaNome }}
                </div>
              </div>

              <div class="p-grid p-mt-1" v-if="locador">
                <div class="p-col-12">
                  <strong><u>Dados do Locador:</u></strong>
                </div>
              </div>

              <div v-if="locador">

                  <div class="p-grid p-mt-1">
                    
                    <div class="p-col-12 p-md-4">
                      <strong>Nome completo</strong><br>
                      {{ locador.nome }}
                    </div>
                    <div class="p-col-12 p-md-3">
                      <strong>RG</strong><br>
                      {{ locador.rg }} {{ (locador.rg_orgao)?locador.rg_orgao:'' }} {{ (locador.rg_expedicao)?'(' + $utils.formatDateToBr(locador.rg_expedicao) + ')':'' }}
                    </div>
                    <div class="p-col-12 p-md-2">
                      <strong>CPF</strong><br>
                      {{ locador.cpfcnpj }}
                    </div>
                    <div class="p-col-12 p-md-2">
                      <strong>Data de Nascimento</strong><br>
                      {{ $utils.formatDateToBr(locador.nascimento) }}
                    </div>

                  </div>

                  <div class="p-grid p-mt-1 row">
                    
                    <div class="p-col-12 p-md-1">
                      <strong>CEP</strong><br>
                      {{ (locador.cep)?locador.cep:'--' }}
                    </div>
                    <div class="p-col-12 p-md-3">
                      <strong>Endereço</strong><br>
                      {{ (locador.logradouro)?locador.logradouro:'--' }}
                    </div>
                    <div class="p-col-12 p-md-1">
                      <strong>Número</strong><br>
                      {{ (locador.numero)?locador.numero:'--' }}
                    </div>
                    <div class="p-col-12 p-md-3">
                      <strong>Complemento</strong><br>
                      {{ (locador.complemento)?locador.complemento:'--' }}
                    </div>
                    <div class="p-col-12 p-md-2">
                      <strong>Bairro</strong><br>
                      {{ (locador.bairro)?locador.bairro:'--' }}
                    </div>
                    <div class="p-col-12 p-md-4">
                      <strong>Cidade</strong><br>
                      {{ (locador.cidade)?locador.cidade:'--' }}
                    </div>
                    <div class="p-col-12 p-md-1">
                      <strong>UF</strong><br>
                      {{ (locador.uf)?locador.uf:'--' }}
                    </div>

                  </div>

                  <div class="p-grid p-mt-1 row">
                    
                    <div class="p-col-12 p-md-6">
                      <strong>Telefones</strong>
                      <div class="contatos" v-if="locador.contatos.length > 0">
                        <span v-for="c of locador.contatos" :key=c.id class="contato">
                        {{ c.telefone }} <span v-if="c.nome">({{ c.nome }})</span>
                        </span>
                      </div>
                      <div v-else>
                        --
                      </div>
                    </div>

                  </div>

              </div>

            </AccordionTab>
            <!-- #endregion -->

            <!-- #region Pretendentes -->
            <AccordionTab header="Pretendentes">

              <div class="pretendentes">
                <div v-for="p of pretendentes" :key="p.id" class="pretendente">

                  <div class="p-grid row">
                    
                    <div class="p-col-12 p-md-4">
                      <strong>Nome completo</strong><br>
                      {{ p.pessoa_item.nome }}
                    </div>
                    <div class="p-col-12 p-md-2">
                      <strong>CPF</strong><br>
                      {{ p.pessoa_item.cpfcnpj }}
                    </div>
                    <div class="p-col-12 p-md-3">
                      <strong>Data de Nascimento</strong><br>
                      {{ $utils.formatDateToBr(p.pessoa_item.nascimento) }}
                    </div>
                    <div class="p-col-12 p-md-2">
                      <strong>Residirá?</strong><br>
                      {{ (p.e_morador)?"Sim":"Não" }}
                    </div>

                  </div>

                  <div class="p-grid p-mt-1 row">
                    
                    <div class="p-col-12 p-md-6">
                      <strong>Telefones</strong>
                      <div class="contatos" v-if="p.pessoa_item.contatos.length > 0">
                        <span v-for="c of p.pessoa_item.contatos" :key=c.id class="contato">
                        {{ c.telefone }} <span v-if="c.nome">({{ c.nome }})</span>
                        </span>
                      </div>
                      <div v-else>
                        --
                      </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                      <strong>E-mail</strong><br>
                      {{ (p.pessoa_item.email)?p.pessoa_item.email:'--' }}
                    </div>

                  </div>

                  <div class="p-grid p-my-2 row">

                    <div class="p-col-12 p-md-6">
                      <div class="p-grid row">

                        <div class="p-col-12">
                          <h6 class="p-mb-0"><u><strong>Renda</strong></u></h6>
                        </div>

                        <div class="p-col-12 p-md-6">
                          <strong>Renda mensal total</strong><br>
                          {{ $utils.formatCurrency(p.pessoa_item.renda_fixa) }}
                        </div>

                        <div class="p-col-12 p-md-6">
                          <strong>Principal fonte de Renda</strong><br>
                          {{ (p.pessoa_item.vinculo_empregaticio_item)?p.pessoa_item.vinculo_empregaticio_item.nome:'--' }}
                        </div>

                      </div>
                    </div>

                    <div class="p-col-12 p-md-6">
                      <div class="p-grid row">

                        <div class="p-col-12">
                          <h6 class="p-mb-0"><u><strong>Cônjuge / Companheiro(a)</strong></u></h6>
                        </div>

                        <div class="p-col-12 p-md-4">
                          <strong>Estado Civil</strong><br>
                          {{ (p.pessoa_item.estado_civil_item)?p.pessoa_item.estado_civil_item.nome:'--' }}
                        </div>

                        <div class="p-col-12 p-md-8">
                          <strong>Conjuge</strong><br>
                          {{ (p.pessoa_item.conjuge_item)?p.pessoa_item.conjuge_item.nome:'--' }}
                        </div>

                      </div>
                    </div>

                  </div>

                </div>

              </div>

            </AccordionTab>
            <!-- #endregion -->

            <!-- #region Coberturas -->
            <AccordionTab header="Coberturas">

              <div class="p-grid">
                
                <div class="p-col-12 p-md-3">
                  <strong>Tempo de contrato</strong><br>
                  {{ meses }} meses
                </div>

                <div class="p-col-12 p-md-3" v-if="indice_reajuste">
                  <strong>Índice de Reajuste do Contrato</strong><br>
                  {{ indice_reajuste }}
                </div>

                <div class="p-col-12 p-md-3" v-if="vigencia_inicio">
                  <strong>Início da Vigência</strong><br>
                  {{ vigencia_inicio }}
                </div>

                <div class="p-col-12 p-md-3" v-if="vigencia_fim">
                  <strong>Fim da Vigência</strong><br>
                  {{ vigencia_fim }}
                </div>

                <div class="p-col-12 p-md-3">
                  <strong>Tipo de Contração</strong><br>
                  {{ (novo)?"Nova Contratação":"Mudança de Garantia" }}
                </div>

                <div class="p-col-12 p-mt-1">
                  <strong><u>Valores da Locação</u></strong>
                </div>

                <div class="p-col-12">
                  <div class="p-grid">

                    <div class="p-col-12 p-md-2">
                      <strong>Aluguel</strong><br>
                      {{ $utils.formatCurrency(valor_aluguel) }}
                    </div>

                    <div class="p-col-12 p-md-2">
                      <strong>Luz</strong><br>
                      {{ $utils.formatCurrency(valor_luz) }}
                    </div>

                    <div class="p-col-12 p-md-2">
                      <strong>Água</strong><br>
                      {{ $utils.formatCurrency(valor_agua) }}
                    </div>

                    <div class="p-col-12 p-md-2">
                      <strong>Gás</strong><br>
                      {{ $utils.formatCurrency(valor_gas) }}
                    </div>

                    <div class="p-col-12 p-md-2">
                      <strong>Condomínio</strong><br>
                      {{ $utils.formatCurrency(valor_condominio) }}
                    </div>

                    <div class="p-col-12 p-md-2">
                      <strong>IPTU</strong><br>
                      {{ $utils.formatCurrency(valor_iptu) }}
                    </div>

                  </div>
                </div>

              </div>

            </AccordionTab>
            <!-- #endregion -->

            <!-- #region Resultados -->
            <AccordionTab header="Resultados">

              <div class="p-grid p-jc-center" v-if="plano_selecionado != null">

                <div class="p-col-3">
                  <Card>
                    <template #header>
                      <div class="plano_selecionado">PLANO SELECIONADO</div>
                      <img :src="'/assets/layout/images/' + plano_selecionado.seguradora_item.logo" alt="" />
                    </template>
                    <template #content>
                      <div class="p-grid">
                        <div class="p-col-12">
                          <div class="planoNome"> {{ (typeof plano_selecionado.plano_item == "object" && plano_selecionado.plano_item != null)?(plano_selecionado.plano_item.nome):(plano_selecionado.plano_integracao_item.nome) }} </div>
                          <div class="planoValor">{{ plano_selecionado.parcelas }}X {{ $utils.formatCurrency(plano_selecionado.valor) }}</div>
                        </div>
                      </div>
                    </template>
                    <template #footer>
                      <div class="p-grid">
                        <div class="p-col-12" v-if="processo.status == 'SEGURO_REGISTRADO'">
                          <Button style="width: 100%" icon="pi pi-check" class="p-button-success" label="Apólice Emitida" @click="(typeof plano_selecionado.impressao == 'object' &&  typeof plano_selecionado.impressao.APOLICE == 'object' && typeof plano_selecionado.impressao.APOLICE.arquivo == 'object')?anexo_abrir(plano_selecionado.impressao.APOLICE.arquivo):()=>{}" />
                        </div>
                        <div class="p-col-12" v-else-if="processo.status == 'REGISTRO_NEGADO'">
                          <Button style="width: 100%; cursor: default;" icon="pi pi-times" class="p-button-danger" label="Registro Negado"/>
                        </div>
                        <div class="p-col-12" v-else-if="processo.status == 'EM_CONTRATACAO'">
                          <Button v-if="$auth.currentUser.imobiliaria != null || Object.values(processo.logs).findIndex((obj) => obj.acao == 'INTEGRACAO_TOO_CONTRATACAO') != -1" style="width: 100%; cursor: default;" icon="pi pi-exclamation-triangle" class="p-button-warning" label="Em Contratação"/>
                          <Button v-else style="width: 100%;" icon="pi pi-bolt" class="p-button-warning" label="CONTRATAR" @click="displayImovelView = true"/>
                        </div>

                        <div class="p-col-12" v-if="processo.status != 'REGISTRO_NEGADO'">
                          <Button style="width: 100%" icon="pi pi-file" label="Cláusulas" @click="anexo_abrir(plano_selecionado.seguradora_item.arquivo_clausulas)" />
                        </div>

                        <div class="p-col-12">
                          <Button style="width: 100%" icon="pi pi-info-circle" @click="mostraCoberturas([plano_selecionado])" label="Coberturas" />
                        </div>
                      </div>

                    </template>
                  </Card>
                </div>

              </div>

              <div class="p-grid p-jc-center" v-else-if="(processo != null && Object.keys(processo.resultados).length > 0)">

                <div class="p-col-3" v-for="s of Object.keys(processo.resultados)" :key="s">
                  <Card>

                    <template #header>
                      <img :src="'/assets/layout/images/' + processo.resultados[s][0].seguradora_item.logo" alt="" />
                    </template>
                    <template #content>
                      <p class="planoNegado p-text-bold p-text-center" v-if="processo.resultados[s][0].acao == 'SEGURADORA_NEGADA'">
                        Cadastro Recusado
                      </p>
                      <div class="p-grid" v-else>
                        <div :class="'p-col-' + (processo.resultados[s].length == 1 ? '12' : '6')" v-for="p of processo.resultados[s]" :key="p.id">
                          <div class="planoNome">
                            {{ (typeof p.plano == "number")?(p.plano_item.nome):(p.plano_integracao_item.nome) }}
                          </div>
                          <div class="planoValor">{{ p.parcelas }}X {{ $utils.formatCurrency(p.valor) }}</div>
                        </div>
                      </div>
                    </template>

                    <template #footer>

                      <div v-if="(processo.resultados[s][0].acao == 'SEGURADORA_NEGADA') && (typeof processo.resultados[s][0].impressao == 'object')">
                        <div v-for="[tipo, arq] of Object.entries(processo.resultados[s][0].impressao)" :key="tipo">
                          <Button v-if="typeof arq == 'string' && arq.toLowerCase().includes('carta') || arq.nome.toLowerCase().includes('carta')" style="width: 100%" icon="pi pi-file" label="Carta de Recusa" @click="obterArquivos(processo.resultados[s][0], tipo)" />
                        </div>
                      </div>

                      <div v-else>

                        <div class="p-grid" v-if="processo.resultados[s].length == 1">

                          <div class="p-col-12" v-if="processo.status != 'EXPIRADO'">
                            <Button style="width: 100%" icon="pi pi-check" label="Contratar" @click="plano_a_selecionar = processo.resultados[s][0]" />
                          </div>

                          <div class="p-col-12">
                            <Button style="width: 100%" icon="pi pi-info-circle" @click="mostraCoberturas(processo.resultados[s])" label="Coberturas" />
                          </div>

                          <div class="p-col-12" v-if="(typeof processo.resultados[s][0].impressao == 'object')">
                            <div v-for="[tipo, arq] of Object.entries(processo.resultados[s][0].impressao)" :key="tipo">
                              <Button v-if="((typeof arq == 'string') && (arq.toLowerCase().includes('carta'))) || ((typeof arq == 'object') && (arq.nome.toLowerCase().includes('carta')))" style="width: 100%" icon="pi pi-file" label="Carta de Aprovação" @click="obterArquivos(processo.resultados[s][0], tipo)" />
                            </div>
                          </div>

                        </div>

                        <div class="p-grid" v-else>

                          <div class="p-col-6" v-if="processo.status != 'EXPIRADO'">
                            <Button style="width: 100%" icon="pi pi-check" label="Contratar" @click="plano_a_selecionar = processo.resultados[s][0]" />
                          </div>

                          <div class="p-col-6" v-if="processo.status != 'EXPIRADO'">
                            <Button style="width: 100%" icon="pi pi-check" label="Contratar" @click="plano_a_selecionar = processo.resultados[s][1]" />
                          </div>

                          <div class="p-col-12">
                            <Button style="width: 100%" icon="pi pi-info-circle" @click="mostraCoberturas(processo.resultados[s])" label="Comparar Planos" />
                          </div>

                          <div class="p-col-12" v-if="(typeof processo.resultados[s][0].impressao == 'object')">
                            <div v-for="[tipo, arq] of Object.entries(processo.resultados[s][0].impressao)" :key="tipo">
                              <Button v-if="((typeof arq == 'string') && (arq.toLowerCase().includes('carta'))) || ((typeof arq == 'object') && (arq.nome.toLowerCase().includes('carta')))" style="width: 100%" icon="pi pi-file" label="Carta de Aprovação" @click="obterArquivos(processo.resultados[s][0], tipo)" />
                            </div>
                          </div>

                        </div>

                      </div>

                    </template>

                  </Card>
                </div>

              </div>

              <div class="p-py-2" v-else>
                <ProcessoAnalise v-model:analisando="analisando" :processo="$route.params.id" @end="analise_finalizada" @endAutomatic="analise_automatica_finalizada" />
              </div>

            </AccordionTab>
            <!-- #endregion -->

            <!-- #region Logs -->
            <AccordionTab header="Acompanhamento">
              <div class="p-grid">
                <div class="p-col-12 p-md-4">
                  <div class="p-p-2">

                    <Button label="registrar log" icon="pi pi-plus" class="p-button" v-if="$auth.currentUser.imobiliaria == null" @click="displayRegistrarLog = true"></Button>

                    <h5><strong>Logs:</strong> <small style="cursor: pointer; text-decoration: underline;" v-if="$auth.userCan('SEGURO_FIANCA_INF_TECNICA')" @click="displayLogErrors = !displayLogErrors">{{ (displayLogErrors)?'ocultar erros':'mostrar erros' }}</small></h5>
                    <div class="logs" v-if="logs.length > 0">
                      <div class="log" v-for="log of logs" :key="log.id">

                        <div class="p-grid" v-if="log.acao.indexOf('INTEGRACAO') == -1 || ((log.detalhes.indexOf('Não finalizada') != -1 || log.detalhes.indexOf('Falha') != -1 || log.detalhes.indexOf('Erros') != -1) && displayLogErrors) || (log.detalhes.indexOf('Não finalizada') == -1 && log.detalhes.indexOf('Falha') == -1 && log.detalhes.indexOf('Erros') == -1)">

                          <div :class="(Object.keys(log.impressao).length > 0 || (log.acao.indexOf('INTEGRACAO') != -1 && $auth.userCan('SEGURO_FIANCA_INF_TECNICA')))?'p-col-8':'p-col-12'">
                            <strong><i class="pi pi-angle-right"></i> Executado por <i>{{ (log.usuario)?log.usuario_item.name + (log.usuario_item.imobiliaria_item?' (' + log.usuario_item.imobiliaria_item.nome + ')':' (Corretora)'):'Sistema' }}</i>
                            em <i>{{ $utils.formatDateTimeToBr(log.data) }}</i></strong>
                          </div>

                          <div class="p-col-4 p-text-right" v-if="Object.keys(log.impressao).length > 0">
                            <Button
                              v-tooltip.top="'Obter Arquivos'"
                              icon="pi pi-file"
                              class="p-button-secondary p-button-rounded p-mr-2"
                              @click="mostrarObterArquivos(log)"
                            ></Button>

                            <Button
                              v-tooltip.top="'Log Técnico'"
                              icon="pi pi-server"
                              class="p-button-secondary p-button-rounded"
                              @click="log_tecnico_id=log.id;displayLogTecnico=true"
                              v-if="log.acao == 'AGUARDANDO_EMISSAO' && $auth.userCan('SEGURO_FIANCA_INF_TECNICA')"
                            ></Button>

                          </div>

                          <div class="p-col-4 p-text-right" v-if="log.acao.indexOf('INTEGRACAO') != -1 && $auth.userCan('SEGURO_FIANCA_INF_TECNICA')">
                            <Button
                              v-tooltip.top="'Buscar Atualização de Status'"
                              icon="pi pi-sync"
                              class="p-button-secondary p-button-rounded p-mr-2"
                              @click="atualizaStatusIntegracao(log.id)"
                              v-if="log.acao.indexOf('ANALISE') != -1"
                            ></Button>
                            <Button
                              v-tooltip.top="'Buscar Atualização de Status'"
                              icon="pi pi-sync"
                              class="p-button-secondary p-button-rounded p-mr-2"
                              @click="atualizaStatusContratacaoIntegracao(log.id)"
                              v-if="log.acao == 'INTEGRACAO_TOO_CONTRATACAO'"
                            ></Button>
                            <!-- <Button
                              v-if="log.seguradora != null"
                              v-tooltip.top="'Reanalizar na ' + log.seguradora_item.nome"
                              icon="pi pi-bolt"
                              class="p-button-secondary p-button-rounded p-mr-2"
                              @click="reanalizar(log)"
                            ></Button> -->
                            <Button
                              v-tooltip.top="'Log Técnico'"
                              icon="pi pi-server"
                              class="p-button-secondary p-button-rounded"
                              @click="log_tecnico_id=log.id;displayLogTecnico=true"
                            ></Button>
                          </div>

                          <div class="p-col-12">
                            <div class="log_seguradora" v-if="log.seguradora">
                              <strong>Seguradora:</strong> <i>{{ log.seguradora_item.nome }}</i>
                            </div>
                            <div class="log_plano" v-if="log.plano">
                              <strong>Plano:</strong> <i>{{ log.plano_item.nome }}</i><br>
                              <strong>Valor:</strong> <i>{{ ((log.parcelas)?log.parcelas+'X ':'') + $utils.formatCurrency(log.valor) }}</i>
                            </div>
                            <div class="log_plano" v-if="log.plano_integracao && (!log.plano || $auth.currentUser.imobiliaria == null)">
                              <strong>Plano <span v-if="$auth.currentUser.imobiliaria == null">Integração</span>:</strong> <i>{{ log.plano_integracao_item.nome }}</i><br>
                              <strong>Valor:</strong> <i>{{ ((log.parcelas)?log.parcelas+'X ':'') + $utils.formatCurrency(($auth.currentUser.imobiliaria == null)?log.plano_integracao_item.valorParcela:log.valor) }}</i>
                            </div>
                            <div class="log_arquivos" v-if="log.arquivos.length > 0">
                                <div class="p-mb-2"><strong>Anexos:</strong></div>
                                <span v-for="a of log.arquivos" :key=a.id>
                                    <Button :label="a.nome" class="p-mr-2 p-my-1 p-button-secondary p-button-sm" style="width: auto" icon="pi pi-file" @click="anexo_abrir(a)"></Button>
                                </span>
                            </div>
                            <div class="log_descricao p-mt-3">
                            {{ log.resumo }}<br v-if="log.detalhes != null"><br v-if="log.detalhes != null"><i style="white-space: break-spaces;">{{ log.detalhes }}</i>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                    <div v-else>
                      Nenhuma nova informação.
                    </div>
                  </div>
                </div>

                <div class="p-col-12 p-md-8">
                  <div class="p-pl-4 p-pr-2 p-py-2 p-ml-2" style="border-left: 1px solid #ccc; min-height: 130px">
                    <h5><strong>Tickets:</strong></h5>
                    <div class="tickets">

                      <Accordion class="">
                        <AccordionTab>
                          <template #header>
                            <span class="p-mr-2">Novo Ticket</span>
                            <i class="pi pi-plus"></i>
                          </template>

                          <div class="p-grid p-fluid">

                            <div class="p-col-12 p-md-6 p-field" v-if="$auth.currentUser.imobiliaria == null">
                              <label for="ticket_destinatario">Destinatário</label>
                              <div class="p-inputgroup">
                                <AutoComplete
                                  id="ticket_destinatario"
                                  v-model="ticket_destinatario"
                                  :suggestions="ticket_destinatarioResultados"
                                  @complete="ticket_destinatarioFiltrarAutoComplete($event)"
                                  @blur="ticket_validaAutoComplete('ticket_destinatario')"
                                  field="label"
                                  optionGroupLabel="label"
                                  optionGroupChildren="items"
                                  placeholder="Informe parte do nome"
                                  :class="
                                    fieldsError.some((v) => v === 'ticket_destinatario') ? 'p-invalid' : ''
                                  "
                                />
                              </div>
                            </div>

                            <div class="p-field p-col-12">
                              <label for="ticket_assunto">Assunto</label>
                              <InputText
                              id="ticket_assunto"
                              type="text"
                              v-model="ticket_assunto"
                              :class="(fieldsError.some(v => v === 'ticket_assunto'))?'p-invalid':''"
                              maxlength="100"
                              />
                            </div>

                            <div class="p-field p-col-12">
                              <label for="ticket_mensagem">Mensagem</label>
                              <Textarea 
                              :autoResize="true"
                              id="ticket_mensagem"
                              v-model="ticket_mensagem"
                              :class="(fieldsError.some(v => v === 'ticket_mensagem'))?'p-invalid':''"
                              rows="5" cols="30"
                              />
                            </div>

                            <div class="p-field p-col-12" v-if="$auth.currentUser.imobiliaria == null">
                              <label for="ticket_mensagem_corretora">Mensagem Interna (Corretora)</label>
                              <Textarea 
                              :autoResize="true"
                              id="ticket_mensagem_corretora"
                              v-model="ticket_mensagem_corretora"
                              :class="(fieldsError.some(v => v === 'ticket_mensagem_corretora'))?'p-invalid':''"
                              rows="1" cols="30"
                              />
                            </div>                        

                            <div class="p-col-12">
                                <FileUpload 
                                    chooseLabel="Anexar Arquivos" 
                                    mode="basic" 
                                    name="upload[]" 
                                    :url="$api.url() + '/arquivos'"
                                    :auto="true" 
                                    :multiple="true"
                                    :maxFileSize="50000000"
                                    accept="image/*,video/*,application/pdf,application/zip"
                                    @before-send="ticket_upload_headers"
                                    @upload="ticket_upload_finalizado"
                                    @progress="ticket_upload_progress"
                                    @error="ticket_upload_error"
                                >
                                </FileUpload>
                            </div>
                            <div class="p-col-12" v-if="ticket_progress_upload > 0 && ticket_progress_upload < 100">
                                <ProgressBar :value="ticket_progress_upload"></ProgressBar>
                            </div>
                            <div class="p-col-12" v-if="ticket_arquivos.length > 0">
                                <span v-for="a of ticket_arquivos" :key=a.id>
                                    <Button :label="a.filename_original" class="p-mr-2 p-button-secondary p-button-sm" style="width: auto" icon="pi pi-file" @click="ticket_upload_click($event, a)"></Button>
                                </span>
                            </div>
                            <div class="p-col-12">
                                <Button label="Criar Ticket" class="p-button-lg p-button-success" style="width: auto" icon="pi pi-check" @click="ticket_criar"></Button>
                            </div>
                          </div>
                        </AccordionTab>
                      </Accordion>

                      <div class="p-fluid p-my-3">

                        <DataTable sortMode="single" :value="tickets" :loading="loading>0" selectionMode="single" v-model:selection="ticket">
                          <Column field="id" header="#" class="col-id"></Column>
                          <Column field="criador_item.name" header="Criador" class="col-criador">
                            <template #body="slotProps">
                              {{ slotProps.data.criador_item.name }} ({{ (!slotProps.data.criador_item.imobiliaria_item)?'Corretora':slotProps.data.criador_item.imobiliaria_item.nome }})
                            </template>
                          </Column>
                          <Column header="Aguardando" class="col-aguardando">
                            <template #body="slotProps">
                              <div v-if="slotProps.data.atual_status_destinatario == 'CONCLUIDO'">
                                -
                              </div>
                              <div v-else-if="slotProps.data.atual_destinatario">
                              {{ slotProps.data.atual_destinatario_item.name }} ({{ (!slotProps.data.atual_destinatario_item.imobiliaria_item)?'Corretora':slotProps.data.atual_destinatario_item.imobiliaria_item.nome }})
                              </div>
                              <div v-else-if="slotProps.data.atual_destinatario_imobiliaria">
                              {{ slotProps.data.atual_destinatario_imobiliaria_item.nome }} (Imobiliária)
                              </div>
                              <div v-else>
                                Corretora
                              </div>
                            </template>
                          </Column>
                          <Column field="data" header="Data de Criação" class="col-data">
                            <template #body="slotProps">
                              {{ $utils.formatDateTimeToBr(slotProps.data.data) }}
                            </template>
                          </Column>
                          <Column field="atual_interacao_data" header="Última Interação" class="col-ultima_interacao">
                            <template #body="slotProps">
                              {{ $utils.formatDateTimeToBr(slotProps.data.atual_interacao_data) }}
                            </template>
                          </Column>
                          <Column field="assunto" header="Assunto" class="col-assunto">
                            <template #body="slotProps">
                              {{ slotProps.data.assunto }}
                            </template>                
                          </Column>
                          <Column field="status" header="Status" class="col-status">
                            <template #body="slotProps">
                              <Tag 
                                v-if="$auth.currentUser.id == slotProps.data.atual_remetente"
                                :value="tickets_status[slotProps.data.atual_status_remetente].label"
                                :icon="tickets_status[slotProps.data.atual_status_remetente].icon" 
                                :severity="tickets_status[slotProps.data.atual_status_remetente].severity">
                              </Tag>
                              <Tag 
                                v-else
                                :value="tickets_status[slotProps.data.atual_status_destinatario].label"
                                :icon="tickets_status[slotProps.data.atual_status_destinatario].icon" 
                                :severity="tickets_status[slotProps.data.atual_status_destinatario].severity">
                              </Tag>
                            </template>
                          </Column>
                          <template #empty>
                              Nenhum Ticket encontrado.
                          </template>
                        </DataTable>

                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </AccordionTab>
            <!-- #endregion -->

          </Accordion>
        
        </div>

      </div>

      <div class="center-loading" v-if="loading>0">
          <ProgressSpinner />
      </div>
      
    </BlockUI>

    <Dialog v-model:visible="displayDetalhes" style="max-width: 98%; width: 719px" :modal="true">
      <template #header>
        <h3 class="p-mb-0" style="text-align: center; width: calc(100% - 20px); font-weight: bold;">Coberturas / Cláusulas ({{ seguradora_nome }})</h3>
      </template>
      <div class="p-grid">
        <div :class="'p-col-' + (seguradora_planos.length == 1 ? '12' : '6')" v-for="p of seguradora_planos" :key="p.id">
          <h4 class="p-text-center planoNome">{{ (typeof p.plano == "number")?(p.plano_item.nome):(p.plano_integracao_item.nome) }}</h4>
          <div class="planoValor">{{ p.parcelas }}X {{ $utils.formatCurrency(p.valor) }}</div>
          <ul class="coberturas">
            <li class="cobertura" v-for="c of ((typeof p.plano == 'number')?(p.plano_item.coberturas):(p.plano_integracao_item.coberturas))" :key="c">
              {{ c }}
            </li>
          </ul>
        </div>
      </div>
    </Dialog>

    <Dialog v-model:visible="displayAcoes" style="max-width: 98%; width: 907px" :modal="true">
      <template #header>
        <h5>Registrar Encaminhamento Manual</h5>
      </template>
      <ProcessoAcaoEdit :processo="processo" @save="encaminhamentoRegistrado" />
    </Dialog>

    <Dialog v-model:visible="displayContrato" style="max-width: 98%; width: 719px" :modal="true">
      <template #header>
        <h5>Enviar Contrato</h5>
      </template>
      <ProcessoAnexarContratoEdit :processo="processo" @save="contratoEnviado" />
    </Dialog>

    <Dialog v-model:visible="displayAceiteContratacao" style="max-width: 98%; width: 700px" :modal="true">
      <template #header>
        <h5><i class="pi pi-file" style="font-size: 1.3rem"></i> Cláusula(s) Contratual(is)</h5>
      </template>
      
      <div style="font-size: 1.2em">

        <p><Button style="width: 100%" icon="pi pi-file" label="o contrato de aluguel deve conter o clausulado disponível aqui" @click="anexo_abrir(plano_a_selecionar.seguradora_item.arquivo_clausulas)" /></p>

        <p><strong>Atenção:</strong> Devem constar no contrato todos os pretendentes indicados na carta de aprovação como locatários solidários. Não é permitida a inclusão de moradores ou locatários não indicados na ficha de análise, sob possível recusa numa eventual indenização de sinistro.</p>
      </div>

      <template #footer>
        <Button style="width: 100%" class="p-button-success" icon="pi pi-check" label="Estou ciente" @click="aceitarContratacao" />
      </template>
    </Dialog>

    <Dialog v-model:visible="displayContratacao" style="max-width: 98%; width: 1200px" :modal="true">
      <template #header>
        <h5><i class="pi pi-plus-circle" style="font-size: 1.3rem"></i> Informações para efetivação</h5>
      </template>
      <ProcessoInfoContratacaoEdit :processo="processo" @save="escolherPlano" />
    </Dialog>

    <Dialog v-model:visible="displayLogTecnico" style="max-width: 98%; width: 700px" :modal="true">
      <template #header>
        <h5><i class="pi pi-server" style="font-size: 1.3rem"></i> Log Técnico da Integração</h5>
      </template>
      <LogIntegracaoView :id="log_tecnico_id" />
    </Dialog>

    <Dialog v-model:visible="displayLogObterArquivos" style="max-width: 98%; width: 250px" :modal="true">
      <template #header>
        <h5><i class="pi pi-file" style="font-size: 1.3rem"></i> Obter Arquivos</h5>
      </template>
      <!-- TODO VERIFICAR E UNIFORMIZAR -->
      <div v-for="[k, l] of Object.entries(log_obter_arquivos.impressao)" :key="k">
        <Button :label="(typeof l == 'string')?l:l.nome" class="p-button-secondary p-mb-2" style="width: 100%" @click="obterArquivos(log_obter_arquivos.id, k)"></Button>
      </div>
    </Dialog>

    <Dialog v-model:visible="displayRegistrarLog" style="max-width: 98%; width: 500px" :modal="true">
      <template #header>
        <h5><i class="pi pi-comment" style="font-size: 1.3rem"></i> Registro de Log</h5>
      </template>
      
      <ProcessoRegistrarLog :processo="$route.params.id" @save="logRegistrado"></ProcessoRegistrarLog>

    </Dialog>

    <Dialog v-model:visible="displayImovelView" style="max-width: 98%; width: 800px" :modal="true" :closable="false" v-if="processo!= null">
      <template #header>
        <h5 class="p-m-0"><strong>O Endereço do Imóvel está Correto?</strong></h5>
      </template>
      <div class="p-mb-4 p-text-center">
        <Button
          class="p-button-success"
          label="Está correto!"
          icon="pi pi-check"
          iconPos="right"
          @click="imovelCorreto"
        />
        <Button
          class="p-button-warning p-ml-4"
          label="Atualizar Endereço"
          icon="pi pi-pencil"
          iconPos="right"
          @click="displayImovelView = false; displayImovelEdit = true"
        />
      </div>
      <ImoveisView :imovel="processo.imovel" />
    </Dialog>

    <Dialog v-model:visible="displayImovelEdit" style="max-width: 98%; width: 1000px" :modal="true" v-if="processo!= null">
      <ImoveisEdit @save="imovelAtualizado" :imobiliaria="processo.imobiliaria_item" :imovel_edit="processo.imovel" />
    </Dialog>

    <ConfirmPopup></ConfirmPopup>
    <Toast position="top-right" />

  </div>

</template>

<script>

import ProcessoAcaoEdit from "./acoes/ProcessoAcaoEdit.vue";
import ProcessoInfoContratacaoEdit from "./acoes/ProcessoInfoContratacaoEdit.vue";
import ProcessoAnexarContratoEdit from "./acoes/ProcessoAnexarContratoEdit.vue";
import ProcessoAnalise from "./acoes/ProcessoAnalise.vue";
import ProcessoRegistrarLog from "./acoes/ProcessoRegistrarLog.vue";
import LogIntegracaoView from "./tecnico/LogIntegracaoView.vue";
import ImoveisEdit from "../imoveis/ImoveisEdit.vue";
import ImoveisView from "../imoveis/ImoveisView.vue";

export default {
  data() {
    return {
      // 1. Controle de apresentação
      analisando: false,
      loading: 2,
      tabsAtivas: [3,4],
      displayDetalhes: false,
      displayAcoes: false,
      displayContrato: false,
      displayAceiteContratacao: false,
      displayContratacao: false,
      displayLogTecnico: false,
      displayLogObterArquivos: false,
      displayRegistrarLog: false,
      displayLogErrors: true,
      displayImovelView: false,
      displayImovelEdit: false,
      seguradoras_reanalizar: [],

      // 1.1. Validadores
      fieldsError: [],

      // 3.1. Informações Gerais
      imovel: "",
      imobiliariaNome: "",
      finalidade: "",
      meses: "0",
      motivo: "",
      ramo: "",
      cnpj: "",
      pretendentes: [],
      novo: 1,

      valor_aluguel: 0,
      valor_luz: 0,
      valor_agua: 0,
      valor_gas: 0,
      valor_condominio: 0,
      valor_iptu: 0,

      cobertura_multas_contratuais: false,
      cobertura_pintura_externa: false,
      cobertura_pintura_interna: false,
      cobertura_danos_imovel: false,

      indice_reajuste: "",
      locador: null,
      vigencia_inicio: "",
      vigencia_fim: "",

      //3.2. Tickets

      ticket_destinatario: null,
      ticket_destinatarioResultados: [],
      ticket_assunto: "",
      ticket_mensagem: "",
      ticket_mensagem_corretora: "",

      ticket_progress_upload: 0,
      ticket_arquivos: [],

      ticket: null,
      tickets: [],
      tickets_status: {
        'NOVO' : {
          label: 'NOVO',
          severity: "info",
          icon: "pi pi-clock",
        },
        'RESPONDIDO' : {
          label: 'RESPONDIDO',
          severity: "warning",
          icon: "pi pi-clock",
        },
        'AGUARDANDO' : {
          label: 'AGUARDANDO',
          severity: "warning",
          icon: "pi pi-clock",
        },
        'CONCLUIDO': {
          label: 'CONCLUÍDO',
          severity: "success",
          icon: "pi pi-check",
        },
        'ARQUIVADO': {
          label: 'ARQUIVADO',
          severity: "danger",
          icon: "pi pi-times",
        },
      },

      // 4. Dado selecionado

      processo : null,
      processo_status: "",

      seguradora_planos: [],
      seguradora_nome: "",

      plano_selecionado: null,
      plano_a_selecionar: null,
      acao_atual: "",

      logs: [],
      log_tecnico_id: 0,

      log_obter_arquivos: null,

      // 5. Chaves Estrangeiras

      // -
      
    };
  },

  mounted() {

    this.carregarObjetoRota();

  },

  watch: {

    $route(atual, anterior) {

      if(atual.name == "processo" || atual.name == "processo-analise") {
        if(atual.name != anterior.name || atual.params.id != anterior.params.id) {
          this.carregarObjetoRota();
        }
      }
        
    },

    processo: function(val) {

      if(typeof val.logs != "undefined") {
        val.logs = Object.values(val.logs);
      } else {
        val.logs = [];
      }

      if(val.imovel_item) {

        const i = val.imovel_item;

        let endereco = [
          i.logradouro,
          i.numero,
          i.complemento,
          i.bairro,
          i.cidade,
          i.uf,
          ((i.cep == "")?"":(i.cep.substring(0,2) + "." + i.cep.substring(2,5) + "-" + i.cep.substring(5)))
        ].filter(v => (typeof v == "string" && v != ""))
        .join(", ");

        this.imovel = [i.id_proprio, i.nome, endereco].filter(v => (typeof v == "string" && v != "")).join(" - ");

      }

      if(val.finalidade_item)
        this.finalidade = val.finalidade_item.nome;

      if(val.meses)
        this.meses = val.meses;

      if(val.motivo_item)
        this.motivo = val.motivo_item.nome;
      else
        this.motivo = this.finalidade;

      if(val.locacao_empresa_cnpj)
        this.cnpj = val.locacao_empresa_cnpj;
      else
        this.cnpj = 'Não informado';

      if(val.ramo_atividade_item)
        this.ramo = val.ramo_atividade_item.nome;
      else
        this.ramo = "Não informado";
      
      if(val.pretendentes) {
        this.pretendentes = val.pretendentes;
        for(const kc in this.pretendentes) {
          if(!this.pretendentes[kc].pessoa_item.contatos)
            this.pretendentes[kc].pessoa_item.contatos = [];
          if(!this.pretendentes[kc].pessoa_item.residencia_situacao_item)
            this.pretendentes[kc].pessoa_item.residencia_situacao_item = {nome: "Não informado."};
        }
      } else
        this.pretendentes = [];

      if(val.valor_aluguel)
        this.valor_aluguel = val.valor_aluguel;
      else
        this.valor_aluguel = 0;

      if(val.valor_luz)
        this.valor_luz = val.valor_luz;
      else
        this.valor_luz = 0;

      if(val.valor_agua)
        this.valor_agua = val.valor_agua;
      else
        this.valor_agua = 0;

      if(val.valor_gas)
        this.valor_gas = val.valor_gas;
      else
        this.valor_gas = 0;

      if(val.valor_condominio)
        this.valor_condominio = val.valor_condominio;
      else
        this.valor_condominio = 0;

      if(val.valor_iptu)
        this.valor_iptu = val.valor_iptu;
      else
        this.valor_iptu = 0;

      if(val.cobertura_multas_contratuais)
        this.cobertura_multas_contratuais = true;
      else
        this.cobertura_multas_contratuais = false;

      if(val.cobertura_pintura_externa)
        this.cobertura_pintura_externa = true;
      else
        this.cobertura_pintura_externa = false;

      if(val.cobertura_pintura_interna)
        this.cobertura_pintura_interna = true;
      else
        this.cobertura_pintura_interna = false;

      if(val.cobertura_danos_imovel)
        this.cobertura_danos_imovel = true;
      else
        this.cobertura_danos_imovel = false;

      if(val.indice_reajuste)
        this.indice_reajuste = val.indice_reajuste;
      else
        this.indice_reajuste = "";

      if(typeof val.imovel_item.locador_item != "undefined") {
        this.locador = val.imovel_item.locador_item;
      } else {
        this.locador = null;
      }

      if(val.vigencia_inicio) {
        this.vigencia_inicio = this.$utils.formatDateToBr(val.vigencia_inicio);
      } else {
        this.vigencia_inicio = "";
      }
      
      if(val.vigencia_fim) {
        this.vigencia_fim = this.$utils.formatDateToBr(val.vigencia_fim);
      } else {
        this.vigencia_fim = "";
      }

      this.plano_selecionado = val.selecionado;

      this.acao_atual = val.acao_atual;
      this.processo_status = val.status;

      if(val.status == "AGUARDANDO_CONTRATO" && this.$auth.currentUser.imobiliaria != null)
        this.displayContrato = true;

      this.logs = Object.values(val.logs);

      this.imobiliariaNome = val.imobiliaria_item.nome;

      this.novo = val.novo;

    },

    ticket: function(val) {
      if(val != null) {
        this.$router.push({ path: `/ticket/${val.id}` });
      }
    },

    plano_a_selecionar: function(val) {
      if(val != null) {
        this.displayAceiteContratacao = true;
      }
    },

    displayContratacao: function(val) {
      if(!val)
        this.plano_a_selecionar = null;
    }

  },

  methods: {

    async initReanalizadores() {

      this.loading++;

      this.seguradoras_reanalizar = [];

      let response = await this.$api.get("/processos/integracoes/seguradoras/" + this.processo.id);
      response = response.data;

      if(response.success) {

        for(let s of response.data) {

          this.seguradoras_reanalizar.push({
            label: s.nome,
            command: () => {
              this.reanalizar({
                processo: this.processo.id,
                seguradora: s.id
              });
            }
          });

        }

      }

      this.loading--;

    },

    _log(obj) {
      console.log(obj);
      return obj;
    },

    reanalizar(log) {

      const self = this;

      self.loading++;

      this.$api
        .get("/processos/integracoes/" + log.seguradora + "/analisar-fianca/" + log.processo)
        .then(function (response) {

          self.loading--;

          self.$toast.add({
            severity: (response.data.success)?"success":"error",
            summary: (response.data.success)?"Sucesso!":"ERRO!",
            detail: response.data.message,
            life: (response.data.success)?3000:5000,
          });

          self.loading = 2;
          self.carregarObjetoRota();

        })
        .catch(function (erro) {

          self.loading--;

          if (typeof erro.response != "undefined") {

            const response = erro.response;

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });

          }

          self.loading = 2;
          self.carregarObjetoRota();

        });

    },

    carregarObjetoRota() {
      const self = this;

      if (
        typeof this.$route.params.id != "string" ||
        this.$route.params.id == ""
      ) {
        self.loading--;
        return;
      }

      this.$api
      .get("/processos/" + this.$route.params.id)
      .then(function (response) {
        const dados = response.data;
        if (dados.success) { 
          self.processo = dados.data;
          self.loading--;

          if(self.$route.name == "processo-analise")
            self.analisando = true;

          self.carregaTickets();
          self.initReanalizadores();

        } else {
          alert(dados.message);
          self.$router.push({ path: `/processos` });
        }
      });

    },

    carregaTickets() {
      const self = this;

      const api_get = '?fields=id,data,atual_interacao_data,assunto,atual_status_destinatario,atual_status_remetente&relationships=criador_item:name;atual_destinatario_item:name;atual_destinatario_imobiliaria_item:nome&filters={%22processo%22:[%22' + this.processo.id + '%22]}';

      this.$api.get('/tickets' + api_get).then(function (response) {

        const dados = response.data;

        if(dados.success) {
          self.tickets = dados.data;
        }

        self.loading--;
        
      });

    },

    mostraCoberturas(planos) {

      this.seguradora_nome = planos[0].seguradora_item.nome;

      for(const k in planos) {
        if(typeof planos[k].plano == 'number' && !Array.isArray(planos[k].plano_item.coberturas))
          planos[k].plano_item.coberturas = planos[k].plano_item.coberturas.split("\n");
        if(typeof planos[k].plano_integracao == 'number' && !Array.isArray(planos[k].plano_integracao_item.coberturas))
          planos[k].plano_integracao_item.coberturas = planos[k].plano_integracao_item.coberturas.split("\n");
      }

      this.seguradora_planos = planos;

      this.displayDetalhes = true;

    },

    async aceitarContratacao() {

      try {

        const response = await this.$api
        .post(
          "/processos/aceite-clausulas",
          {
            "log": this.plano_a_selecionar.id,
          }
        );

        if(response.data.success) {

          this.displayAceiteContratacao = false;
          this.displayContratacao = true;

        } else {

          this.$toast.add({
            severity: "error",
            summary: "ERRO!",
            detail: response.data.message,
            life: 5000,
          });

        }

      } catch (erro) {

        if (typeof erro.response != "undefined") {

          const response = erro.response;

          this.$toast.add({
            severity: "error",
            summary: "ERRO!",
            detail: response.data.message,
            life: 5000,
          });

        }

      }

    },

    escolherPlano(event) {

      this.displayContratacao = false;

      this.processo.imovel_item = this.$utils.getStdObject(event.imovel);

      this.processo.vigencia_inicio = event.contrato.vigencia_inicio;
      this.processo.vigencia_fim = event.contrato.vigencia_fim;
      this.processo.indice_reajuste = event.contrato.indice_reajuste;
      this.processo.valor_aluguel = event.contrato.valor_aluguel;

      const self = this;

      this.$api
        .post(
          "/processos/escolher-plano",
          {
            "indice_reajuste": event.contrato.indice_reajuste,
            "vigencia_inicio": event.contrato.vigencia_inicio,
            "vigencia_fim": event.contrato.vigencia_fim,
            "log": this.plano_a_selecionar.id,
            "arquivos": event.contrato.arquivos,
            "valor_aluguel": event.contrato.valor_aluguel
          }
        )
        .then(function (response) {

          self.$toast.add({
            severity: (response.data.success)?"success":"error",
            summary: (response.data.success)?"Sucesso!":"ERRO!",
            detail: response.data.message,
            life: (response.data.success)?3000:5000,
          });

          if(response.data.success) {
            self.plano_selecionado = response.data.data;

            self.loading = 2;
            self.carregarObjetoRota();

          }

        })
        .catch(function (erro) {

          if (typeof erro.response != "undefined") {

            const response = erro.response;

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });

          }

        });

    },

    logRegistrado() {
      this.displayRegistrarLog = false;
      this.carregarObjetoRota();
    },

    registrarEncaminhamento() {
      this.displayAcoes = true;
    },

    enviarContrato() {
      this.displayContrato = true;
    },

    encaminhamentoRegistrado() {
      this.displayAcoes = false;
      this.loading++;
      this.carregarObjetoRota();
    },

    contratoEnviado() {
      this.displayContrato = false;
      this.loading++;
      this.carregarObjetoRota();
    },

    imovelCorreto() {
      this.displayImovelView = false;
      this.contratar();
    },

    imovelAtualizado(imovel) {
      if(imovel != null) {
        this.displayImovelEdit = false;
        this.contratar();
      }
    },

    contratar() {

      const self = this;

      self.loading++;

      this.$api
        .get("/processos/integracoes/contratar-fianca/" + this.$route.params.id)
        .then(function (response) {

          self.loading--;

          self.$toast.add({
            severity: (response.data.success)?"success":"error",
            summary: (response.data.success)?"Sucesso!":"ERRO!",
            detail: response.data.message,
            life: (response.data.success)?3000:5000,
          });

          if(response.data.success) {            
            self.loading = 2;
            self.carregarObjetoRota();
          }

        })
        .catch(function (erro) {

          self.loading = 2;
          self.carregarObjetoRota();

          if (typeof erro.response != "undefined") {

            const response = erro.response;

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });

          }

        });


    },

    mostrarObterArquivos(log) {

      this.log_obter_arquivos = log;
      this.displayLogObterArquivos = true;

    },

    obterArquivos(log, tipo) {

      this.displayLogObterArquivos = false;

      let _log = log;
      if(typeof log == "number")
        _log = this.processo.logs.find((l) => l.id == log);
        
      if(typeof _log.impressao[tipo] == "object") {
        this.anexo_abrir(_log.impressao[tipo].arquivo);
        return;
      }

      const self = this;

      self.loading++;

      this.$api
        .get("/processos/integracoes/obter-arquivos/" + _log.id + "/" + tipo)
        .then(function (response) {

          self.loading--;

          self.$toast.add({
            severity: (response.data.success)?"success":"error",
            summary: (response.data.success)?"Sucesso!":"ERRO!",
            detail: response.data.message,
            life: (response.data.success)?3000:5000,
          });

          if(response.data.success) {            

            window.open(response.data.data);

          } else {

            self.loading = 2;
            self.carregarObjetoRota();

          }

        })
        .catch(function (erro) {

          self.loading--;

          if (typeof erro.response != "undefined") {

            const response = erro.response;

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });

          }

        });

    },

    anexo_abrir(arquivo) {
      window.open(this.$api.url() + '/arquivos/' + arquivo.nome, '_blank');
    },

    ticket_validaAutoComplete(val) {
      if(typeof this[val] != "object")
        this[val] = null;
    },

    ticket_destinatarioFiltrarAutoComplete(event) {

      const self = this;

      const q = event.query.toLowerCase();

      this.$api
        .get("/tickets/destinatarios?str=" + q)
        .then(function (response) {
          const dados = response.data;
          if (dados.success) {

            self.ticket_destinatarioResultados = dados.data;

          }
        });

    },

    ticket_upload_headers(event) {
        event.xhr.setRequestHeader("Authorization", "Bearer " + this.$auth.token());
    },

    ticket_upload_progress(event) {
        this.ticket_progress_upload = event.progress;
    },

    ticket_upload_error(event) {
        const res = event.xhr.response;

        self.$toast.add({
            severity: "error",
            summary: "ERRO!",
            detail: res.message,
            life: 5000,
        });

    },

    ticket_upload_click(event, file) {

        const self = this;

        this.$confirm.require({
            target: event.currentTarget,
            message: 'O que deseja fazer?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Abrir",
            rejectLabel: "Excluir",
            acceptClass: "p-button-success",
            rejectClass: "p-button-danger",
            accept: () => {
                window.open(self.$api.url() + '/arquivos/' + file.filename_final, '_blank');
            },
            reject: () => {

                self.$api
                    .post(
                    "/arquivos/" + file.id, {"_method" : "DELETE"})
                    .then(function (response) {

                        const idx = self.ticket_arquivos.findIndex(e => e.id == file.id);
                        self.ticket_arquivos.splice(idx, 1);

                        self.$toast.add({
                            severity: (response.data.success)?"success":"error",
                            summary: (response.data.success)?"Sucesso!":"ERRO!",
                            detail: response.data.message,
                            life: (response.data.success)?3000:5000,
                        });

                    })
                    .catch(function (erro) {

                        if (typeof erro.response != "undefined") {

                            const response = erro.response;

                            self.$toast.add({
                                severity: "error",
                                summary: "ERRO!",
                                detail: response.data.message,
                                life: 5000,
                            });
                        }

                    });

            }
        });

    },

    ticket_upload_finalizado(event) {
        const res = JSON.parse(event.xhr.response);
        this.ticket_arquivos = this.ticket_arquivos.concat(res.data);
    },

    ticket_limparFormulario() {

      this.ticket_assunto = "";
      this.ticket_mensagem = "";
      this.ticket_mensagem_corretora = "";

      for(const a of this.ticket_arquivos) {
        this.$api.post("/arquivos/" + a.id, {"_method" : "DELETE"});
      }

      this.ticket_arquivos = [];

    },

    ticket_criar() {
      const self = this;

      this.fieldsError = [];

      let dados = {
        "assunto": this.ticket_assunto,
        "mensagem": this.ticket_mensagem,
        "mensagem_corretora": this.ticket_mensagem_corretora,
        "processo": this.processo.id,
      };

      if(this.ticket_destinatario)
        dados[this.ticket_destinatario.field] = this.ticket_destinatario.id;

      if(this.ticket_arquivos.length > 0) {
        dados['arquivos'] = [];
        for(const a of this.ticket_arquivos) {
          dados['arquivos'].push(a.id);
        }
      }

      this.$api
        .post(
          "/tickets",
          dados
        )
        .then(function (response) {

          self.$toast.add({
            severity: (response.data.success)?"success":"error",
            summary: (response.data.success)?"Sucesso!":"ERRO!",
            detail: response.data.message,
            life: (response.data.success)?3000:5000,
          });

          self.ticket_arquivos = [];

          self.ticket_limparFormulario();

          self.carregaTickets();

        })
        .catch(function (erro) {

          if (typeof erro.response != "undefined") {

            const response = erro.response;

            if(typeof response.data.fields != "undefined") {

              const dicionario = {

                assunto: "ticket_assunto",
                mensagem: "ticket_mensagem",
                mensagem_corretora: "ticket_mensagem_corretora",
                mensagem_interna: "ticket_mensagem_interna",
                destinatario: "ticket_destinatario",
                arquivos: "ticket_arquivos",

              };

              for(const f of response.data.fields) {

                if(typeof dicionario[f] != "undefined") {
                  self.fieldsError.push(dicionario[f]);
                } else {
                  self.fieldsError.push(f);
                }

              }

            }

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });

          }

        });
    },

    atualizaStatusIntegracao(id) {

      const self = this;

      self.loading++;

      this.$api
        .get("/processos/integracoes/atualizar-status-fianca/" + id)
        .then(function (response) {

          self.loading--;

          self.$toast.add({
            severity: (response.data.success)?"success":"error",
            summary: (response.data.success)?"Sucesso!":"ERRO!",
            detail: response.data.message,
            life: (response.data.success)?3000:5000,
          });

          if(response.data.success) {            
            self.loading = 2;
            self.carregarObjetoRota();
          }

        })
        .catch(function (erro) {

          self.loading--;

          if (typeof erro.response != "undefined") {

            const response = erro.response;

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });

          }

        });

    },

    atualizaStatusContratacaoIntegracao(id) {

      const self = this;

      self.loading++;

      this.$api
      .get("/processos/integracoes/atualizar-status-contratacao-fianca/" + id)
      .then(function (response) {

        self.loading--;

        self.$toast.add({
          severity: (response.data.success)?"success":"error",
          summary: (response.data.success)?"Sucesso!":"ERRO!",
          detail: response.data.message,
          life: (response.data.success)?3000:5000,
        });

        if(response.data.success) {            
          self.loading = 2;
          self.carregarObjetoRota();
        }

      })
      .catch(function (erro) {

        self.loading--;

        if (typeof erro.response != "undefined") {

          const response = erro.response;

          self.$toast.add({
            severity: "error",
            summary: "ERRO!",
            detail: response.data.message,
            life: 5000,
          });

        }

      });

    },

    analise_finalizada() {
      if(this.$route.name == "processo-analise") {
        this.$router.push({ path: `/processo/${this.$route.params.id}` });
      } else {
        this.carregarObjetoRota();
      }
    },

    analise_automatica_finalizada() {
      this.carregarObjetoRota();
    }

  },
  components: {
    ProcessoAcaoEdit,
    ProcessoInfoContratacaoEdit,
    ProcessoAnexarContratoEdit,
    LogIntegracaoView,
    ProcessoAnalise,
    ProcessoRegistrarLog,
    ImoveisEdit,
    ImoveisView
  }
};
</script>

<style scoped>

  :deep(.row) {
    margin-top: 1rem;
  }

  :deep(.contato) {
    border-left: 1px solid;
    padding-left: 0.5em;
    margin-left: 0.35em;
  }

  :deep(.contato:first-child) {
    border-left: 0;
    padding-left: 0;
    margin-left: 0;
  }

  :deep(.pretendente) {
    border-top: 2px solid #ccc;
    margin-top: 1rem;
  }

  :deep(.pretendentes .pretendente:first-child) {
    border-top: none;
    margin-top: 0;
  }

  :deep(.planoNegado) {
    font-size: 1.7em;
    color: rgb(184, 10, 10);
  }

  :deep(.planoNome) {
    font-size: 1.7em;
    font-weight: 700;
    text-align: center;
  }

  :deep(.planoValor) {
    text-align: center;
    font-size: 1.3em;
    font-weight: 600;
    margin-top: 1em;
  }

  :deep(li.cobertura) {
    margin-bottom: 15px;
  }

  :deep(.coberturas) {
    margin-top: 1.5em;
    list-style: none;
    padding-left: 0;
  }

  :deep(.log .p-grid) {
    border-top: 1px solid #ccc;
    margin-top: 1em;
    padding-top: 0.6em;
  }

  :deep(.logs .log:first-child .p-grid) {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }

  :deep(.plano_selecionado) {
    background-color: #d52626;
    color: white;
    text-align: center;
    padding: 7px;
    font-weight: bold;
  }

  :deep(.button-contrato) {
    background-color: #f47921 !important;
    border-color: #f47921 !important;
    color: white !important;
  }

  :deep(.p-field) {
    margin-bottom: 0;
  }

</style>