<template>

  <BlockUI :blocked="loading>0">

    <div class="p-grid">

      <div class="p-col-12">
        <h5><i class="pi pi-user-edit" style="font-size: 1.5rem"></i> Dados do Locador</h5>
      </div>

      <div class="p-col-12 p-md-6 p-pt-3">
        <!-- Informações Básicas -->
        <h5><i class="pi pi-id-card"></i> Identificação</h5>
        <div class="card p-fluid">
          <div class="p-grid">
            <div class="p-field p-md-3">
                <label for="cpf">CPF/CNPJ</label>
                <InputText
                id="cpf"
                type="text"
                v-model="cpf"
                maxlength="18"
                @focusin="clearMaskCPFCNPJ"
                @blur="validaCPFCNPJ"
                @focus="cpfInvalido = false; cpfPrevioValidacao = cpf"
                :class="(fieldsError.some(v => v === 'cpf'))?'p-invalid':''"
                />
                <InlineMessage v-if="cpfInvalido">CPF Inválido</InlineMessage>
            </div>

            <div class="p-field p-md-9">
                <label for="nome">Nome Completo</label>
                <InputText
                id="nome"
                type="text"
                v-model="nome"
                maxlength="200"
                :class="(fieldsError.some(v => v === 'nome'))?'p-invalid':''"
                />
            </div>

            <div class="p-field p-md-3" v-show="doc == 'cpf'">
                <label for="rg">RG</label>
                <InputText id="rg" type="text" v-model="rg" maxlength="20" 
                :class="(fieldsError.some(v => v === 'rg'))?'p-invalid':''" />
            </div>

            <div class="p-field p-md-3" v-show="doc == 'cpf'">
                <label for="rg_orgao">Órgão Emissor</label>
                <InputText
                id="rg_orgao"
                type="text"
                v-model="rg_orgao"
                :class="(fieldsError.some(v => v === 'rg_orgao'))?'p-invalid':''"
                maxlength="10"
                />
            </div>

            <div class="p-field p-md-3" v-show="doc == 'cpf'">
                <label for="rg_expedicao">Expedição</label>
                <InputMask
                id="rg_expedicao"
                v-model="rg_expedicao"
                mask="99/99/9999"
                slotChar="dd/mm/aaaa"
                :class="(fieldsError.some(v => v === 'rg_expedicao'))?'p-invalid':''"
                />
            </div>

            <div class="p-field p-md-3" v-show="doc == 'cpf'">
                <label for="nascimento">Nascimento</label>
                <InputMask
                v-model="nascimento"
                :class="(fieldsError.some(v => v === 'nascimento'))?'p-invalid':''"
                mask="99/99/9999"
                slotChar="dd/mm/aaaa"
                />
            </div>

            <div class="p-field p-col-12">
              <label for="email">E-Mail</label>
              <InputText
                id="email"
                type="text"
                v-model="email"
                :class="(fieldsError.some(v => v === 'email'))?'p-invalid':''"
                maxlength="200"
              />
            </div>

          </div>
        </div>

        <!-- Telefones -->
        <h5><i class="pi pi-phone"></i> Telefones</h5>
        <div class="card p-fluid p-mb-0">
          <div class="p-grid">

            <div class="p-field p-md-6">
              <label for="telefone">Número de Telefone</label>
              <InputMask
                id="telefone"
                type="text"
                v-model="telefone"
                :class="(fieldsError.some(v => v === 'telefone'))?'p-invalid':''"
                mask="(99) 9999-9999?9"
                @focus="telefoneInvalido = false"
              />
              <InlineMessage v-if="telefoneInvalido">
                O Telefone é inválido
              </InlineMessage>
            </div>

            <div class="p-field p-md-6">
              <label for="telefone_nome">Observações (opcional)</label>
              <InputText
                id="telefone_nome"
                type="text"
                v-model="telefone_nome"
                :class="(fieldsError.some(v => v === 'telefone_nome'))?'p-invalid':''"
                maxlength="150"
              />
            </div>

            <div class="p-field p-md-6">
              <div class="p-field-checkbox">
                <Checkbox
                    id="e_whatsapp"
                    v-model="telefone_e_whatsapp"
                    :binary="true"
                />
                <label for="e_whatsapp">O telefone é WhatsApp.</label>
              </div>
            </div>

            <div class="p-field p-md-6">
              <Button
                label="Adicionar Telefone"
                @click="adicionarTelefone"
              ></Button>
            </div>

            <div class="p-field" v-if="telefones.length">
              <DataTable sortMode="single" :value="telefones">
                <Column field="nome" headerClass="colNome" header="Observações">
                  <template #body="slotProps">
                    {{
                        slotProps.data.nome
                        ? slotProps.data.nome
                        : "Nenhuma"
                    }}
                  </template>
                </Column>
                <Column
                  field="telefone"
                  headerClass="colTelefone"
                  headerStyle="width:145px"
                  header="Telefone"
                >
                  <template #body="slotProps">
                    {{ $utils.formatPhone(slotProps.data.telefone) }}
                  </template>
                </Column>
                <Column
                  field="tipo"
                  headerClass="colTipo"
                  headerStyle="width:67px"
                  header="Tipo"
                ></Column>
                <Column
                  field="e_whatsapp"
                  headerClass="colWhatsapp"
                  headerStyle="width:65px"
                  header="Whatsapp?"
                >
                  <template #body="slotProps">
                    {{ slotProps.data.e_whatsapp ? "Sim" : "Não" }}
                  </template>
                </Column>
                <Column headerStyle="width:52px">
                  <template #body="slotProps">
                    <Button
                      icon="pi pi-trash"
                      class="p-mr-2 p-mb-2 p-button-danger"
                      @click="removerTelefone($event, slotProps.index)"
                    ></Button>
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-12 p-md-6">
        
        <!-- Endereço -->
        <h5><i class="pi pi-map-marker"></i> Endereço do Locador</h5>
        <div class="card p-fluid">
          <div class="p-grid">
            <div class="p-field p-md-3">
              <label for="residencia_cep">CEP</label>
              <InputMask
                id="residencia_cep"
                type="text"
                v-model="residencia_cep"
                :class="(fieldsError.some(v => v === 'residencia_cep'))?'p-invalid':''"
                mask="99.999-999"
                :unmask="true"
                @change="validaCEP"
                @focus="cepInvalido = false; cepPrevioValidacao = residencia_cep"
              />
              <InlineMessage v-if="cepInvalido">CEP Inválido</InlineMessage>
            </div>

            <div class="p-field p-md-9">
              <label for="residencia_logradouro">Endereço</label>
              <InputText
                id="residencia_logradouro"
                type="text"
                v-model="residencia_logradouro"
                :class="(fieldsError.some(v => v === 'residencia_logradouro'))?'p-invalid':''"
                maxlength="300"
              />
            </div>

            <div class="p-field p-md-2">
              <label for="residencia_numero">Número</label>
              <InputText
                id="residencia_numero"
                type="text"
                v-model="residencia_numero" :class="(fieldsError.some(v => v === 'residencia_numero'))?'p-invalid':''"
                maxlength="10"
              />
            </div>

            <div class="p-field p-md-10">
              <label for="residencia_complemento">Complemento</label>
              <InputText
                id="residencia_complemento"
                type="text"
                v-model="residencia_complemento" :class="(fieldsError.some(v => v === 'residencia_complemento'))?'p-invalid':''"
                maxlength="150"
              />
            </div>

            <div class="p-field p-md-5">
              <label for="residencia_bairro">Bairro</label>
              <InputText
                id="residencia_bairro"
                type="text"
                v-model="residencia_bairro" :class="(fieldsError.some(v => v === 'residencia_bairro'))?'p-invalid':''"
                maxlength="150"
              />
            </div>

            <div class="p-field p-md-5">
              <label for="residencia_cidade">Cidade</label>
              <InputText
                id="residencia_cidade"
                type="text"
                v-model="residencia_cidade" :class="(fieldsError.some(v => v === 'residencia_cidade'))?'p-invalid':''"
                maxlength="100"
              />
            </div>

            <div class="p-field p-md-2">
              <label for="residencia_uf">UF</label>
              <InputText
                id="residencia_uf"
                type="text"
                v-model="residencia_uf" :class="(fieldsError.some(v => v === 'residencia_uf'))?'p-invalid':''"
                maxlength="20"
              />
            </div>

          </div>
        </div>

        <!-- Outras informações -->
        <h5 v-show="doc == 'cpf'"><i class="pi pi-plus"></i> Outras informações</h5>
        <div class="card p-fluid p-mb-0" v-show="doc == 'cpf'">
          <div class="p-grid">

            <div class="p-field p-md-6">
              <label for="atividadeExc">Profissão</label>
              <InputText id="atividadeExc" type="text" v-model="profissao" :class="(fieldsError.some(v => v === 'profissao'))?'p-invalid':''" />
            </div>

            <div class="p-field p-md-6">
              <label for="estado_civil">Estado Civil</label>
              <Dropdown
                id="estado_civil"
                v-model="estado_civil" :class="(fieldsError.some(v => v === 'estado_civil'))?'p-invalid':''"
                :options="estados_civis"
                optionLabel="nome"
                optionValue="id"
                placeholder="Selecione"
              />
            </div>

          </div>
        </div>

      </div>

      <div class="p-col-12">
        <h5><i class="pi pi-file" style="font-size: 1.5rem"></i> Dados do Contrato de Locação</h5>
      </div>

      <div class="p-col-12 p-pt-3">
        <div class="card p-fluid p-mb-0">
          <div class="p-grid">

            <div class="p-field p-md-3">
              <label for="indice_reajuste">Índice de Reajuste</label>
              <Dropdown
                id="indice_reajuste"
                v-model="indice_reajuste" 
                :class="(fieldsError.some(v => v === 'indice_reajuste'))?'p-invalid':''"
                :options="indices_reajuste"
                placeholder="Selecione"
              />
            </div>

            <div class="p-field p-md-3">
              <label for="vigencia_inicio">Início da Vigência</label>
              <InputMask
                id="vigencia_inicio"
                v-model="vigencia_inicio"
                mask="99/99/9999"
                slotChar="dd/mm/aaaa"
                :class="(fieldsError.some(v => v === 'vigencia_inicio'))?'p-invalid':''"
              />
            </div>

            <div class="p-field p-md-3">
              <label for="vigencia_fim">Fim da Vigência</label>
              <InputMask
                id="vigencia_fim"
                v-model="vigencia_fim"
                mask="99/99/9999"
                slotChar="dd/mm/aaaa"
                :class="(fieldsError.some(v => v === 'vigencia_fim'))?'p-invalid':''"
              />
            </div>

            <div class="p-field p-md-3">
              <label for="valor_aluguel">Valor do Aluguel</label>
              <InputNumber
                id="valor_aluguel"
                mode="currency"
                currency="BRL"
                locale="pt-BR"
                v-model="valor_aluguel"
                :class="
                  fieldsError.some((v) => v === 'valor_aluguel')
                    ? 'p-invalid'
                    : ''
                "
                @focus="$utils.inputNumberFocus"
              />
            </div>

            <div class="p-col-12 p-mt-2">
              <FileUpload 
                chooseLabel="Anexar Arquivos" 
                mode="basic" 
                name="upload[]" 
                :url="$api.url() + '/arquivos'"
                :auto="true" 
                :multiple="true"
                :maxFileSize="50000000"
                accept="image/*,video/*,application/pdf,application/zip"
                @before-send="upload_headers"
                @upload="upload_finalizado"
                @progress="upload_progress"
                @error="upload_error"
              >
              </FileUpload>
            </div>
            <div class="p-col-12" v-show="progress_upload > 0 && progress_upload < 100">
              <ProgressBar :value="progress_upload"></ProgressBar>
            </div>
            <div class="p-col-12 p-mt-2" v-show="arquivos.length > 0">
              <span v-for="a of arquivos" :key=a.id>
                <Button :label="a.filename_original" class="p-mr-2 p-button-secondary p-button-sm" style="width: auto" icon="pi pi-file" @click="upload_click($event, a)"></Button>
              </span>
            </div>


            <div class="p-md-9 p-mt-3">
              <p style="color: red; font-size: 1.2em;">
                <strong>Todas as informações acima são de inteira responsabilidade da imobiliária e de suma importância para emissão correta da proposta de seguro. As coberturas serão contratadas de acordo com as informações prestadas nesta solicitação, salvo as situações que impeçam a efetivação das mesmas.</strong>
              </p>
            </div>

            <div class="p-md-3 p-mt-3">

              <Button
                id="buttonGravar"
                label="Concluir"
                icon="pi pi-check"
                style="width: initial; float: right; margin-top: 1em"
                class="p-button-lg p-button-success"
                @click="gravar($event)" 
              ></Button>

            </div>

          </div>
        </div>
      </div>

    </div>

    <div class="center-loading" v-if="loading>0">
      <ProgressSpinner />
    </div>

  </BlockUI>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />
</template>

<script>
import axios from "axios";

export default {
  props: {
    processo: Object,
  },
  emits: [
    "save"
  ],
  data() {
    return {

      // 1. Controle de apresentação
      loading: 1,

      // 1.1. Validadores
      telefoneInvalido: false,
      cepInvalido: false,
      cepPrevioValidacao: "",
      cpfInvalido: false,
      cpfPrevioValidacao: "",
      fieldsError: [],

      // 2. Chaves Estrangeiras
      estados_civis: [],
      indices_reajuste: [
        "IPCA",
        "IGPM",
        "SALMIN",
        "INPC",
        "MAIOR",
        "IGP-DI",
        "IGP-M",
        "IPCAICV",
        "TJSP",
        "IGPDI",
        "IVAR"
      ],

      // 3. Dados de formulários

      // 3.1. Informações Gerais

      doc: "cpf",
      cpf: "",
      nome: "",
      rg: "",
      rg_orgao: "",
      rg_expedicao: "",
      nascimento: "",
      email: "",

      telefone_nome: "",
      telefone_e_whatsapp: false,
      telefone: "",

      telefones: [],

      residencia_cep: "",
      residencia_logradouro: "",
      residencia_numero: "",
      residencia_complemento: "",
      residencia_bairro: "",
      residencia_cidade: "",
      residencia_uf: "",

      profissao: "",

      estado_civil: "",

      vigencia_inicio: "",
      vigencia_fim: "",
      indice_reajuste: "MAIOR",
      valor_aluguel: 0,

      // 4. Dado selecionado para edição

      pessoa: null,

      progress_upload: 0,
      arquivos : [],
      
    };
  },

  mounted() {
    const self = this;

    this.$api.get("/pessoas-estados-civis").then(function (response) {
      const dados = response.data;

      if (dados.success) {
        self.estados_civis = dados.data;
      }

      self.loading--;

    });

    if(typeof this.processo != "undefined" && typeof this.processo.imovel_item != "undefined" && this.processo.imovel_item.locador_item != "undefined") {
      this.pessoa = this.processo.imovel_item.locador_item;
    }

    this.vigencia_inicio = this.$utils.formatDateToBr(this.processo.vigencia_inicio);
    this.vigencia_fim = this.$utils.formatDateToBr(this.processo.vigencia_fim);
    this.valor_aluguel = parseFloat(this.processo.valor_aluguel);

  },

  watch: {

    pessoa: function (val) {
      
      this.limparFormulario();

      if (val != null) {

        // 3. Dados de formulários

        // 3.1. Informações Gerais

        this.cpf = val.cpfcnpj;
        this.nome = val.nome;
        this.rg = val.rg;
        this.rg_orgao = val.rg_orgao;
        this.rg_expedicao = this.$utils.formatDateToBr(val.rg_expedicao);
        this.nascimento = this.$utils.formatDateToBr(val.nascimento);
        this.email = val.email;

        if(Array.isArray(val.contatos)) {
          for(const c of val.contatos) {
            this.telefones.push({
              nome: c.nome,
              telefone: c.telefone,
              tipo: c.tipo,
              e_whatsapp: c.e_whatsapp,
            });
          }
        }
        
        this.residencia_cep = val.cep;
        this.residencia_logradouro = val.logradouro;
        this.residencia_numero = val.numero;
        this.residencia_complemento = val.complemento;
        this.residencia_bairro = val.bairro;
        this.residencia_cidade = val.cidade;
        this.residencia_uf = val.uf;

        this.estado_civil = val.estado_civil;

        this.profissao = val.profissao;

      }
    },

  },

  methods: {

    limparFormulario() {

      // 1.1. Validadores
      this.telefoneInvalido = false;
      this.cepInvalido = false;
      this.cpfInvalido = false;

      // 3. Dados de formulários

      // 3.1. Informações Gerais

      this.cpf = "";
      this.nome = "";
      this.rg = "";
      this.rg_orgao = "";
      this.rg_expedicao = "";
      this.nascimento = "";
      this.email = "";

      this.telefone_nome = "";
      this.telefone_e_whatsapp = false;
      this.telefone = "";

      this.telefones = [];

      this.residencia_cep = "";
      this.residencia_logradouro = "";
      this.residencia_numero = "";
      this.residencia_complemento = "";
      this.residencia_bairro = "";
      this.residencia_cidade = "";
      this.residencia_uf = "";

      this.profissao = "";

      this.estado_civil = "";
      
      this.vigencia_inicio = "";
      this.vigencia_fim = "";
      this.indice_reajuste = "";
      this.valor_aluguel = 0;

    },

    validaCEP() {

      if(this.residencia_cep == "" || this.cepPrevioValidacao == this.residencia_cep)
        return;

      const self = this;

      axios
        .get("https://brasilapi.com.br/api/cep/v1/" + this.residencia_cep)
        .then(function (response) {
          //Sucesso
          self.residencia_uf = response.data.state;
          self.residencia_cidade = response.data.city;
          self.residencia_bairro = response.data.neighborhood;
          self.residencia_logradouro = response.data.street;
        })
        .catch(function (error) {
          //Erro
          if (error.response.data.name == "CepPromiseError")
            self.cepInvalido = true;
        });
    },

    clearMaskCPFCNPJ() {
      this.cpf = this.cpf.replace(/[^0-9]/g, "");
    },

    validaCPFCNPJ() {

      const cpf = this.cpf.replace(/[^0-9]/g, "");

      if(cpf == "" || this.cpfPrevioValidacao == cpf)
        return;

      this.cpfPrevioValidacao = cpf;

      this.cpfInvalido = false;
      if(cpf.length == 14) {
        if (this.$utils.isCNPJ(cpf)) {
          this.cpf = this.$utils.formatCpfCnpj(cpf);
          this.doc = "cnpj";
          this.validaCadastrado();
        } else {
          this.cpfInvalido = true;
        }
      } else if(cpf.length == 11) {
        if (this.$utils.isCPF(cpf)) {
          this.doc = "cpf";
          this.cpf = this.$utils.formatCpfCnpj(cpf);
          this.validaCadastrado();
        } else {
          this.cpfInvalido = true;
        }
      } else {
        this.cpfInvalido = true;
      }

    },

    // // // AÇÕES DO USUÁRIO // // //

    adicionarTelefone(telefone = "") {

      if(typeof telefone != "string" || telefone == "")
        telefone = this.telefone.replace(/[^0-9]/g, "");

      if (telefone.length == 11 || telefone.length == 10) {
        this.telefoneInvalido = false;

        this.telefones.push({
          nome: this.telefone_nome,
          telefone: this.$utils.formatPhone(telefone),
          tipo: telefone.substr(2, 1) == "9" ? "Móvel" : "Fixo",
          e_whatsapp: (this.telefone_e_whatsapp)?1:0,
        });
      } else {
        this.telefoneInvalido = true;
      }
    },

    removerTelefone(event, index) {
      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: "Tem certeza que deseja excluir?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          self.telefones.splice(index, 1);
        },
      });
    },

    validaDados() {

      this.fieldsError = [];

      if(this.cpf == "") this.fieldsError.push("cpf");
      if(this.nome == "") this.fieldsError.push("nome");
      if(this.email == "") this.fieldsError.push("email");

      if(this.cpf.replace(/[^0-9]/g,"").length == 11) {

        if(this.rg == "") this.fieldsError.push("rg");
        if(this.rg_orgao == "") this.fieldsError.push("rg_orgao");
        if(this.rg_expedicao == "") this.fieldsError.push("rg_expedicao");
        if(this.nascimento == "") this.fieldsError.push("nascimento");

        if(this.profissao == "") this.fieldsError.push("profissao");

        if(this.estado_civil == "") this.fieldsError.push("estado_civil");

      }

      if(this.telefones.length == 0) {
        this.fieldsError.push("telefone");
        this.fieldsError.push("telefone_nome");
        this.fieldsError.push("telefone_e_whatsapp");
      }

      if(this.residencia_cep == "") this.fieldsError.push("residencia_cep");
      if(this.residencia_logradouro == "") this.fieldsError.push("residencia_logradouro");
      if(this.residencia_numero == "") this.fieldsError.push("residencia_numero");
      // if(this.residencia_complemento == "") this.fieldsError.push("residencia_complemento");
      if(this.residencia_bairro == "") this.fieldsError.push("residencia_bairro");
      if(this.residencia_cidade == "") this.fieldsError.push("residencia_cidade");
      if(this.residencia_uf == "") this.fieldsError.push("residencia_uf");

      if(this.vigencia_inicio == "") this.fieldsError.push("vigencia_inicio");
      if(this.vigencia_fim == "") this.fieldsError.push("vigencia_fim");
      if(this.indice_reajuste == "") this.fieldsError.push("indice_reajuste");
      
      if(this.valor_aluguel == 0 || this.valor_aluguel  > this.processo.valor_aluguel) {
        
        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "O Valor do Aluguel não pode ser 0 (zero) nem maior que o valor analisado!",
          life: 5000,
        });

        this.fieldsError.push("valor_aluguel");
        
        return false;

      }

      if(this.fieldsError.length > 0) {

        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "Preencha corretamente todas as informações!",
          life: 5000,
        });

        return false;
      }

      return true;

    },

    gravar(event) {

      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: 'Confirma esta ação?',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-success',
        accept: () => {

          if(self.telefones.length == 0 && self.telefone.length > 0)
            self.adicionarTelefone();

          if(!self.validaDados()) return;

          const criar = (self.pessoa == null);

          self.fieldsError = [];

          let dados = {
            "tipo": ((self.doc == 'cpf')?'PF':'PJ'),
            "nome": self.nome,
            "cpfcnpj": self.cpf.replace(/[^0-9]/g, ""),
            "nascimento": self.$utils.formatDateToMysql(self.nascimento),
            "email": self.email,
            "logradouro": self.residencia_logradouro,
            "numero": self.residencia_numero,
            "complemento": self.residencia_complemento,
            "bairro": self.residencia_bairro,
            "cidade": self.residencia_cidade,
            "uf": self.residencia_uf,
            "cep": self.residencia_cep.replace(/[^0-9]/g, ""),
            "estado_civil": self.estado_civil,
            
            "profissao": self.profissao,

            "rg": self.rg,
            "rg_orgao": self.rg_orgao,
            "rg_expedicao": self.$utils.formatDateToMysql(self.rg_expedicao),

            "contatos": self.telefones,

            "imobiliaria": self.processo.imobiliaria,

          };

          if (!criar) {
            dados["_method"] = "PUT";
          }

          self.$api
            .post(
              "/pessoas" + (!criar ? "/" + self.pessoa.id : ""),
              dados
            )
            .then(function (response) {

              const dadosPessoa = response.data.data;

              let dados_imovel = self.$utils.getStdObject(self.processo.imovel_item);
              delete dados_imovel.locador_item;
              delete dados_imovel.id;
              
              dados_imovel.locador = dadosPessoa.id;

              dados_imovel["_method"] = "PUT";

              self.$api
                .post(
                  "/imoveis/" + self.processo.imovel,
                  dados_imovel
                )
                .then(function (response) {

                  const dadosImovel = response.data.data;

                  let _arquivos = [];
                  if(self.arquivos.length > 0) {
                    for(const a of self.arquivos) {
                      _arquivos.push(a.id);
                    }
                  }

                  self.$emit("save", {
                    "pessoa": dadosPessoa,
                    "imovel": dadosImovel,
                    "contrato": {
                      "indice_reajuste" : self.indice_reajuste,
                      "vigencia_inicio" : self.$utils.formatDateToMysql(self.vigencia_inicio),
                      "vigencia_fim" : self.$utils.formatDateToMysql(self.vigencia_fim),
                      "valor_aluguel" : self.valor_aluguel,
                      "arquivos" : _arquivos,
                    }
                  });

                }).catch(function (erro) {

                  if (typeof erro.response != "undefined") {

                    const response = erro.response;

                    if(typeof response.data.fields != "undefined") {

                      const dicionario = {
                      };

                      for(const f of response.data.fields) {

                        if(typeof dicionario[f] != "undefined") {
                          self.fieldsError.push(dicionario[f]);
                        } else {
                          self.fieldsError.push(f);
                        }

                      }

                    }

                    self.$toast.add({
                      severity: "error",
                      summary: "ERRO!",
                      detail: response.data.message,
                      life: 5000,
                    });

                  }

                });

            })
            .catch(function (erro) {

              if (typeof erro.response != "undefined") {

                const response = erro.response;

                if(typeof response.data.fields != "undefined") {

                  const dicionario = {
                    "logradouro": "residencia_logradouro",
                    "numero": "residencia_numero",
                    "complemento": "residencia_complemento",
                    "bairro": "residencia_bairro",
                    "cidade": "residencia_cidade",
                    "uf": "residencia_uf",
                    "cep": "residencia_cep",                
                  };

                  for(const f of response.data.fields) {

                    if(typeof dicionario[f] != "undefined") {
                      self.fieldsError.push(dicionario[f]);
                    } else {
                      self.fieldsError.push(f);
                    }

                  }

                }

                self.$toast.add({
                  severity: "error",
                  summary: "ERRO!",
                  detail: response.data.message,
                  life: 5000,
                });

              }

            });

        }
      });

    },

    upload_headers(event) {
        event.xhr.setRequestHeader("Authorization", "Bearer " + this.$auth.token());
    },

    upload_progress(event) {
        this.progress_upload = event.progress;
    },

    upload_error(event) {
        const res = event.xhr.response;

        self.$toast.add({
            severity: "error",
            summary: "ERRO!",
            detail: res.message,
            life: 5000,
        });

    },

    upload_click(event, file) {

        const self = this;

        this.$confirm.require({
            target: event.currentTarget,
            message: 'O que deseja fazer?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Abrir",
            rejectLabel: "Excluir",
            acceptClass: "p-button-success",
            rejectClass: "p-button-danger",
            accept: () => {
                window.open(self.$api.url() + '/arquivos/' + file.filename_final, '_blank');
            },
            reject: () => {

                self.$api
                    .post(
                    "/arquivos/" + file.id, {"_method" : "DELETE"})
                    .then(function (response) {

                        const idx = self.arquivos.findIndex(e => e.id == file.id);
                        self.arquivos.splice(idx, 1);

                        self.$toast.add({
                            severity: (response.data.success)?"success":"error",
                            summary: (response.data.success)?"Sucesso!":"ERRO!",
                            detail: response.data.message,
                            life: (response.data.success)?3000:5000,
                        });

                    })
                    .catch(function (erro) {

                        if (typeof erro.response != "undefined") {

                            const response = erro.response;

                            self.$toast.add({
                                severity: "error",
                                summary: "ERRO!",
                                detail: response.data.message,
                                life: 5000,
                            });
                        }

                    });

            }
        });

    },

    upload_finalizado(event) {
        const res = JSON.parse(event.xhr.response);
        this.arquivos = this.arquivos.concat(res.data);
    },

    async validaCadastrado() {

      try {

        this.loading++;

        const response = await this.$api
          .get("/pessoas?relationships=contatos&filters={%22imobiliaria%22:[%22" + this.processo.imobiliaria + "%22],%22str%22:[%22" + this.cpf.replace(/[^0-9]/g, "") + "%22]}");

        const dados = response.data;

        if (dados.success && dados.data.length > 0) { 
          this.pessoa = dados.data[0];
          this.loading--;
        } else {

          if(!this.cpfInvalido) {

            const response = await this.$api
              .get("/pessoas/apicpfcnpj/" + this.cpf.replace(/[^0-9]/g, ""));

            const dados = response.data;

            if (dados.success) { 

              if(dados.data.type == "cpf") {

                this.nome = dados.data.nome;
                this.nascimento = dados.data.nascimento;

              } else {

                this.nome = dados.data.nome;
                this.residencia_cep = dados.data.cep;
                this.residencia_logradouro = dados.data.logradouro;
                this.residencia_numero = dados.data.numero;
                this.residencia_complemento = dados.data.complemento;
                this.residencia_bairro = dados.data.bairro;
                this.residencia_cidade = dados.data.cidade;
                this.residencia_uf = dados.data.uf;
                this.email = dados.data.email;

                if(dados.data.telefone != "") {
                  this.adicionarTelefone(dados.data.telefone);
                }

              }

            }

            this.loading--;

          }

        }

      } catch (error) {
        this.loading--;
        console.log("Falha ao verificar pessoa já cadastrada!");
        console.log(error);
      }

    },

  },
};
</script>

<style scoped>
</style>